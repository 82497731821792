import React from "react";

// import Button from "./Button";
import { printUnit } from "../../utils/ar";
import Button from "./Button";
import Container from "./Container";
import { printFullDateTime } from "../../utils/time";
import payment from "../../services/paymentServices";

const OrderCard = ({
    total_price = "",
    created_at,
    payment_time,
    id,
    customer_delivery_price,
    invoice_id = "",
    invoice_key = "",
    invoice_status = "pending",
    invoice_provider,
    order_status = "pending",
    products = [],
    ...props
}) => {
    return (
        <div className="group  shadow-md rounded-lg bg-moGreen-900 dark:bg-moGreen-200">
            <Container className=" px-1 sm:px-4  ">
                <div className="flex flex-center-both  space-y-5 flex-col">
                    <div className="text-secBlue-700 flex-center-both font-bold text-lg">
                        {printFullDateTime(created_at)}
                    </div>
                    <div className="divider-1  bg-slate-600" />
                </div>
                <div className="flex flex-col space-y-5">
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            المنتجات :
                        </p>
                        {/* products names here */}
                        {products.map((product, index) => (
                            <span key={index} className="text-gray-600">
                                {product.quantity} : {product.name}
                            </span>
                        ))}
                    </div>
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            السعر الكلي :
                        </p>
                        {/* order total price here */}
                        {printUnit(total_price, "جنيه")}
                    </div>
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            سعر الشحن :
                        </p>
                        {/* products names here */}
                        {printUnit(customer_delivery_price, "جنيه")}
                    </div>
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            لينك الفاتورة :
                        </p>
                        {/* products names here */}
                        <Button
                            className="text-sm md:text-lg flex flex-center-both leading-3 py-0 md:py-2 px-2 md:px-4"
                            element="a"
                            color="indigo"
                            href={payment.getInvoiceUrl({
                                invoice_id,
                                invoice_key,
                                invoice_provider,
                            })}
                        >
                            هنا
                        </Button>
                    </div>
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            حالة الدفع :
                        </p>
                        {/* products names here */}
                        {invoice_status === "pending" ? (
                            <span className="py-1 px-2 bg-rose-600 text-white rounded-md">
                                لم يتم الدفع
                            </span>
                        ) : (
                            <span className="py-1 px-2 bg-emerald-600 text-white rounded-md">
                                {invoice_status}
                            </span>
                        )}
                    </div>
                    {invoice_status === "paid" ? (
                        <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                            <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                                وقت الدفع :
                            </p>
                            <span className="">{printFullDateTime(payment_time)}</span>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="flex gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            حالة التوصيل :
                        </p>
                        {/* products names here */}
                        {order_status === "pending" ? (
                            <span className="py-1 px-2 bg-rose-600 text-white rounded-md">
                                {order_status}
                            </span>
                        ) : (
                            <span className="py-1 px-2 bg-emerald-600 text-white rounded-md">
                                {order_status}
                            </span>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default OrderCard;
