import { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import { isMultiYear } from "../../services/defaultSettings";
import { getYearPlaceHolder } from "../../services/yearSevices";
import { printFullDateTime } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import http from "../../services/httpServices";
import Button from "../../components/ui/Button";
import { printGovernment, printUnit } from "../../utils/ar";
import { Link } from "react-router-dom";

const UsersTable = () => {
    const initialState = {
        type: "user",
        government_id: 0,
        full_name: "",
        phone: "",
        father_phone: "",
        provider_invoice_id: "",
        city: "",
        school_name: "",
    };

    const [filterData, setFilterData] = useState(initialState);

    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "الاسم الكامل",
            reorder: true,
            selector: (row) => row.full_name,
            sortable: true,
            sortField: "full_name",
        },
    ];
    if (filterData.type === "outlet") {
        columns = [
            ...columns,
            {
                name: "اسم منفذ البيع",
                reorder: true,
                selector: (row) => row.school_name,
                sortable: true,
                sortField: "school_name",
            },
        ];
    }
    columns = [
        ...columns,

        {
            name: "رقم الهاتف",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    element="Link"
                    to={`../user_profile/${row.id}`}
                >
                    {(row.phone + "").padStart(11, "0")}
                </Link>
            ),
            sortable: true,
            sortField: "phone",
        },
    ];

    columns = [
        ...columns,
        {
            name: "رقم هاتف بديل",
            reorder: true,
            selector: (row) => (row.father_phone ? (row.father_phone + "").padStart(11, "0") : ""),
            sortable: true,
            sortField: "father_phone",
        },
        {
            name: "المحافظة",
            reorder: true,
            selector: (row) => printGovernment(governments, row.government_id),
            sortable: true,
            sortField: "government_id",
        },
        {
            name: "المدينه",
            reorder: true,
            selector: (row) => row.city,
            sortable: true,
            sortField: "city",
        },
        {
            name: "عدد الاوردرات",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    to={`../orders?user_id=${row.id}`}
                >
                    {printUnit(row.orders_count, "اوردر")}
                </Link>
            ),
            sortable: true,
            sortField: "orders_count",
        },
    ];
    if (filterData.type === "user") {
        columns = [
            ...columns,
            {
                name: "عدد الاوردرات المدفوعه",
                reorder: true,
                selector: (row) => (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        to={`../orders?user_id=${row.id}&invoice_status=paid`}
                    >
                        {printUnit(row.paid_orders_count, "اوردر")}
                    </Link>
                ),

                sortable: true,
                sortField: "paid_orders_count",
            },
        ];
        if (isMultiYear) {
            columns.push({
                name: "العام الدراسي",
                reorder: true,
                selector: (row) => row.year,
                format: (row) => getYearPlaceHolder(row.year),
                sortable: true,
                sortField: "year",
            });
        }
    }
    columns = [
        ...columns,
        {
            name: "تاريخ انشاء الحساب",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDateTime(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];

    const [data, setData] = useState(initialState);

    const [governments, setGovernments] = useState([]);

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }
    useEffect(() => {
        getGovernments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="type"
                        placeholder="نوع الحساب"
                        type="select"
                        options={[
                            {
                                value: "user",
                                text: "حساب طالب",
                            },
                            {
                                value: "outlet",
                                text: "حساب منفذ بيع",
                            },
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        // className="lg:col-span-2"
                    />
                    <InputField
                        id="government_id"
                        placeholder="المحافظة"
                        type="select"
                        options={[
                            {
                                value: 0,
                                text: "الكل",
                            },
                            ...governments,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="full_name"
                        placeholder="اسم المستخدم"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="phone"
                        placeholder="رقم هاتف المستخدم \ رقم هاتف البديل"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="provider_invoice_id"
                        placeholder="رقم فاتوره"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="city"
                        placeholder="اسم المدينه"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    {data.type === "outlet" ? (
                        <InputField
                            id="school_name"
                            placeholder="اسم المنفذ"
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/users/paginate`}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default UsersTable;
