import { useContext, useEffect, useState } from "react";
import { ArctIcons, HeroImg, HomeSvgDark } from "../../Constants/Svgs";
import Button from "../../components/ui/Button";
import "./MainSection.css";
import Container from "../../components/ui/Container";
import Header from "../../components/ui/Header";
import AuthContext from "../../context/AuthContext";
import ThemeContext from "../../context/ThemeContext";
import { MainSvg } from "./MainSvg";
const MainSection = ({
    isBestSellings = false,
    isFilterByYear = false,
    isProducts = false,
    isMegaBuyer = false,
}) => {
    const { token } = useContext(AuthContext) || {};
    let Default = "مش بس كٌتبك كلها في مكان واحد كمان هتلاقي منتجات تانيه هتساعدك.. فوق وركز";
    const [content, setContent] = useState(Default);

    useEffect(() => {
        if (isBestSellings) {
            setContent(
                "اتفرج علي الكتب الجامده لو انت بتدور علي كتب مستر صلاح الأكثر مبيعاً بين الطلبة "
            );
        } else if (!isBestSellings && isFilterByYear) {
            setContent(
                "تقدر توفر وقتك وده من خلال انك تحدد و تختار براحتك كتابك بالسنة الدراسية والترم الدراسي"
            );
        } else if (!isBestSellings && isProducts) {
            setContent(
                "شوية دلع كده عشان تذاكر بنفس تقدر تشتري اي حاجه من صلاح ستور احنا موفرينهالك!"
            );
        } else if (isMegaBuyer && !isBestSellings) {
            setContent(
                "أهلا بيك معانا في صلاح ستور تقدر من خلال الصفحة دي تطلب كل المنتجات الي محتاجها "
            );
        } else {
            setContent(Default);
        }
    }, [Default, isBestSellings, isFilterByYear, isMegaBuyer, isProducts]);

    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin relative overflow-hidden">
                <Container className="h-full relative z-10 pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row flex-col h-full space-y-10 md:space-y-0 ">
                        <div className="basis-full md:basis-1/2 h-full flex justify-center">
                            <div className="flex items-center md:items-start justify-center flex-col space-y-6 md:space-y-0 max-w-[480px]">
                                <div className="relative z-10 pt-3 w-fit">
                                    <h1 className="font-big before-box flex justify-center md:justify-start relative">
                                        <span className="font-w-bold relative smooth">
                                            <Header
                                                title="حمثلاح"
                                                coloredTitle="ستور"
                                                textColor="text-secBlue-600"
                                            />
                                        </span>
                                    </h1>
                                    <h2 className="pt-4 space-y-2  flex-col font-Noto">
                                        <div className="font-h2 text-center md:text-right ">
                                            {content}
                                        </div>
                                    </h2>
                                    {!isMegaBuyer && (
                                        <div className="pt-8 pb-20 md:pb-0 flex justify-center md:justify-start">
                                            {!token ? (
                                                <Button
                                                    className="flex gap-2"
                                                    color="emerald"
                                                    element="Link"
                                                    to="/register"
                                                >
                                                    أنشئ حسابك الآن !
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="flex gap-2"
                                                    color="emerald"
                                                    element="Link"
                                                    to="/bestsellings"
                                                >
                                                    اشتري الأن
                                                    <span>
                                                        <img
                                                            src={ArctIcons}
                                                            className="fill-secBlue-100"
                                                            alt="Salah-store-hero-imagery"
                                                        />
                                                    </span>
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="basis-full md:basis-1/2 h-full flex-center-both pt-20 md:pt-0">
                            <div className="w-full h-full flex-center-both max-w-lg">
                                <MainSvg />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
