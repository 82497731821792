/* eslint-disable react/prop-types */
const HalfPage = ({
    picture,
    children,
    isLogin = false,
    isAdminLogin = false,
    isStoreRegister = false,
}) => {
    return (
        <div className="halfPageSection flex flex-col lg:flex-row lg:min-h-screen space-y-10 lg:space-y-0">
            <div className="lg:basis-2/5 min-w-0">
                <div
                    className="h-64 lg:h-full w-full overflow-hidden flex flex-center-both relative scalable-image"
                    // style={{
                    //     backgroundImage: "url(" + picture + ")",
                    //     backgroundSize: "cover",
                    //     backgroundPosition: "center center",
                    //     // backgroundRepeat: "repeat-y",
                    // }}
                >
                    <img
                        className="h-full lg:h-auto relative z-10  "
                        src={picture}
                        alt="panorama"
                    />
                    <div
                        className={`w-full h-3/4 absolute -right-10  my-auto rounded-3xl ${
                            isLogin
                                ? `bg-[#7FD9F5] dark:bg-[#2B454D]`
                                : isAdminLogin
                                ? `bg-purple-200 dark:bg-purple-900`
                                : isStoreRegister
                                ? `bg-[#f890a2] dark:bg-[#36344F]`
                                : `bg-[#ADA7F1] dark:bg-[#36344F]`
                        } `}
                    />

                    {/* <div className="h-full w-full inset-0 absolute form-img-cover smooth opacity-0 dark:opacity-70"></div>
                    <div className="block lg:hidden h-full w-full inset-0 absolute smooth bg-slate-900 opacity-50"></div> */}
                </div>
            </div>
            <div className="lg:basis-3/5 flex-center-both pb-nav-margin">{children}</div>
        </div>
    );
};

export default HalfPage;
