import Container from "../components/ui/Container";
import HeaderCardsSection from "../components/ui/HeaderCardsSection";
import HeroSection from "../sections/home/MainSection";
import { ProductSection } from "../sections/Products/ProductSection";
import { isMultiYear } from "../services/defaultSettings";
import { useContext, useEffect, useState } from "react";
import { semester, years } from "../services/yearSevices";
import { handleInputChange } from "../services/formServices";
import InputField from "../components/form/elements/InputField";
import AuthContext from "../context/AuthContext";

const FilterByYear = () => {
    const { user } = useContext(AuthContext);
    const [filterData, setFilterData] = useState({
        year: 3,
        semester_id: 1,
    });

    const toUseYears = years;
    const toUseSemesters = semester;

    useEffect(() => {
        if (user && user.year) {
            setFilterData({ ...filterData, year: user.year });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, user.year]);
    return (
        <Container>
            <HeroSection isFilterByYear={true} />

            <div className="flex flex-col items-center justify-center gap-[104px] lg:gap-30">
                <div className="flex flex-col items-center justify-center h-fit gap-10">
                    <HeaderCardsSection
                        title="كٌــــتبك"
                        coloredTitle="بالسنة الدراسية"
                        colorClass="text-moGreen-400"
                    />
                    <div className="flex-center-both flex-col sm:flex-row px-10 py-4 sm:py-8 rounded-2xl space-y-4 sm:space-y-0 sm:gap-10 bg-slate-300 dark:bg-slate-800 smooth ">
                        {isMultiYear ? (
                            <InputField
                                onChange={handleInputChange}
                                data={filterData}
                                setData={setFilterData}
                                type="select"
                                options={toUseYears}
                                id="year"
                                placeholder="اختر الصف الدراسي"
                                // errors={errors}
                            />
                        ) : (
                            ""
                        )}
                        {parseInt(filterData.year) !== 3 ? (
                            <InputField
                                onChange={handleInputChange}
                                data={filterData}
                                setData={setFilterData}
                                type="select"
                                options={toUseSemesters}
                                id="semester_id"
                                placeholder="اختر الترم"
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <ProductSection
                    product_type="book"
                    api={`/api/user/products?product_type=book&year=${filterData.year}&semester=${filterData.semester_id}&limit=9`}
                    isDecoreRight={false}
                    isShowMore={false}
                    redirect_link="/years"
                    limited={false}
                />
                {/* { here are svg decore} */}
            </div>
        </Container>
    );
};

export default FilterByYear;
