import React from "react";
import { Navigate } from "react-router-dom";

import auth from "../services/authServices";
import { adminPath, outletPath } from "../services/defaultSettings";

const NotBuyer = ({ children }) => {
    const authToken = auth.getOutletToken() ? true : false;

    return !authToken ? (
        <>{children}</>
    ) : (
        <>
            <Navigate to={`/${outletPath + "outlet/home"}`} replace />
        </>
    );
};

export default NotBuyer;
