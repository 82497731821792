import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import auth from "../services/authServices";

const FullAddressEntered = ({ children, replace = true }) => {
    // const { user } = useContext(AuthContext);
    const localStorageUser = auth.getUser();
    let fullAddresEntered = false;

    let location = useLocation();

    if (
        localStorageUser.city &&
        localStorageUser.address &&
        localStorageUser.father_phone &&
        localStorageUser.city.trim() !== "" &&
        localStorageUser.address.trim() !== ""
    ) {
        fullAddresEntered = true;
    }
    return fullAddresEntered ? (
        <>{children}</>
    ) : (
        <Navigate
            to="/me/user/my-address"
            state={{ prevPath: location.pathname, from: location.pathname }}
            replace={replace}
        />
    );
};

export default FullAddressEntered;
