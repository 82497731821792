const localCart = "cart";

const getCart = () => {
    return localStorage.getItem(localCart);
};

const storeCart = (cart) => {
    localStorage.setItem(localCart, JSON.stringify(cart));
};

const cartService = {
    getCart,
    storeCart,
};
export default cartService;
