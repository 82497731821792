import React from "react";
import HeaderCardsSection from "../components/ui/HeaderCardsSection";
import { ProductSection } from "../sections/Products/ProductSection";

const MegaBuyerHome = () => {
    return (
        <div className="flex flex-col items-center justify-center gap-[104px] lg:gap-30">
            <div className="flex flex-col items-center justify-center h-fit gap-10">
                <HeaderCardsSection
                    title="تصفح جميع"
                    coloredTitle="منتجاتنا"
                    colorClass="text-moGreen-400"
                />
            </div>
            <ProductSection
                product_type="book"
                api={`/api/outlet/products`}
                isOutlet={true}
                isDecoreRight={true}
                isShowMore={false}
                limited={false}
                isMegaBuyer={true}
            />
            {/* { here are svg decore} */}
        </div>
    );
};

export default MegaBuyerHome;
