import React, { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import { printFullDateTime } from "../../utils/time";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { printUnit } from "../../utils/ar";
import { isObjectEmpty } from "../../utils/objects";
import Table from "../../components/ui/Table";
import CenterIcon from "../../components/ui/CenterIcon";
import modal from "../../services/modalServices";
import { Link } from "react-router-dom";
import { adminPath } from "../../services/defaultSettings";

const OrderPreparationGroupsHome = () => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => (
                <Link
                    className="underline"
                    to={`/${adminPath}/panel/order_preparation_groups/${row.id}`}
                >
                    {row.id}
                </Link>
            ),
            sortable: true,
            sortField: "id",
        },
        {
            name: "العنوان",
            reorder: true,
            selector: (row) => (
                <Link
                    className="underline"
                    to={`/${adminPath}/panel/order_preparation_groups/${row.id}`}
                >
                    {row.name}
                </Link>
            ),
            sortable: true,
            sortField: "name",
        },
        {
            name: "عدد الاوردرات",
            reorder: true,
            selector: (row) => printUnit(row.order_quantity, "اوردر"),
            sortable: true,
            sortField: "order_quantity",
        },
        {
            name: "عدد المنتجات",
            reorder: true,
            selector: (row) => printUnit(row.products_quantity, "منتج"),
            sortable: true,
            sortField: "products_quantity",
        },
        {
            name: "تاريخ انشاء الطلب",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDateTime(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];

    const [fetchedData, setFetchedData] = useState({});

    const getGroupsData = async () => {
        try {
            const token = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(token);
            const { data: response } = await http.get(
                `/api/order_preparation_groups/get_groups`,
                config
            );
            setFetchedData(response);
        } catch (error) {}
    };
    const handleGroupingOrders = async () => {
        try {
            const token = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(token);
            const { data: response } = await http.post(
                `/api/order_preparation_groups/group_orders`,
                {},
                config
            );
            setFetchedData({
                ...response,
                groups: [...response.new_groups, ...fetchedData.groups],
            });
            modal.message({
                title: "تم توزيع الاوردرات بنجاح",
                text: "تم توزيع الاوردرات بنجاح",
            });
            // setFetchedData(response);
        } catch (error) {}
    };

    useEffect(() => {
        getGroupsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminContainer>
            {!isObjectEmpty(fetchedData) ? (
                <div className="w-full space-y-6">
                    <div className="bg-cyan-800 text-white dark:bg-cyan-900 smooth shadow-md rounded p-6 mb-6 text-center">
                        {/* <h2 className="text-2xl font-bold mb-6">بيانات واحصائيات المقبولين</h2> */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div
                                className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both bg-rose-500 hover-shadow cursor-pointer flex-col"
                                onClick={handleGroupingOrders}
                            >
                                <span className="font-h1">
                                    <CenterIcon icon="fluent-mdl2:product-release" />
                                </span>
                                <h3 className="text-lg font-semibold">
                                    وزع الاوردرات اللي مش متوزعه!
                                </h3>
                            </div>
                            <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col">
                                <h3 className="text-lg font-semibold">
                                    الاوردرات الغير موزعه حتى الآن
                                </h3>
                                <span className="text-2xl font-bold">
                                    {fetchedData.ungrouped_orders_count}
                                </span>
                            </div>
                            <div className="p-4 border dark:border-sky-600 rounded shadow-sm hover:bg-secYellow-500 dark:hover:bg-sky-700 hover:shadow-lg  smooth flex-center-both text-center flex-col">
                                <h3 className="text-lg font-semibold">
                                    الاوردرات الغير موجوده في اي بوليصه حتى الآن
                                </h3>
                                <span className="text-2xl font-bold">
                                    {fetchedData.unfiled_orders_count}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Table columns={columns} data={fetchedData.groups} />
                    {/* <RemoteTable
                        api={`/api/applicants/paginate`}
                        // toEdit={}
                        isToEditData={isToEditData}
                        setIsToEditData={setIsToEditData}
                        editableData={editableData}
                        setEditableData={setEditableData}
                        columns={columns}
                        setExtraData={setExtraData}
                        filterData={filterData}
                    /> */}
                </div>
            ) : (
                <div>
                    <LoadingIcon className={"font-h1"} />
                </div>
            )}
        </AdminContainer>
    );
};

export default OrderPreparationGroupsHome;
