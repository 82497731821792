import React from "react";
import HeaderCardsSection from "../../components/ui/HeaderCardsSection";
import Container from "../../components/ui/Container";
import AddressEdit from "./AddressEdit";

const MyAddress = () => {
    return (
        <>
            <div className="flex flex-col space-y-10">
                <section className="flex flex-col flex-center-both space-y-10 px-10">
                    <HeaderCardsSection
                        title="بيانات"
                        coloredTitle="الأستلام"
                        colorClass="text-goBlue-600"
                    />
                    <p className="w-full md:w-2/3  text-md text-center text-slate-700 dark:text-slate-100">
                        لو عايز تضيف او تعدل بيانات إستلام الأوردرات ورجاء اتأكد من بياناتك وسيتم
                        التحديث تلقائيا في الاوردرات الجديدة
                    </p>
                </section>

                <Container>
                    <AddressEdit />
                </Container>
            </div>
        </>
    );
};

export default MyAddress;
