import { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import InputIcon from "../../components/form/elements/InputIcon";
import {
    handleCrud,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import { submitTypes } from "../../services/adminServices";

const initialState = {
    name: "",
    comment: "",
    shipment_code: "",
    total_quantity: 0,
    arrival_time: "",
    shipment_id: 0,
    submit_type: 0,
    // Assuming this is a string, update according to your data type
};

const ShipmentEditing = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [shipments, setShipments] = useState([]);

    const [inputFields, setInputFields] = useState([]);

    const getShipments = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(`/api/shipments/options`, config);
        setShipments(response);
    };

    useEffect(() => {
        if (data.submit_type && data.submit_type !== "store") {
            getShipments();
        }
    }, [data.submit_type]);
    const getShipmentInfo = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/shipments/${data.shipment_id}`, config);
        let result = {};
        Object.keys(response).forEach((element) => {
            if (
                ["name", "comment", "shipment_code", "arrival_time", "total_quantity"].includes(
                    element
                )
            ) {
                result[element] = response[element];
            }
        });

        setData({ ...data, ...result });
        console.log("heeeeeeeeeeeeeeeeeeeere info");
        console.log(data);
    };
    useEffect(() => {
        if (data.shipment_id > 0 && data.submit_type && data.submit_type !== "store") {
            getShipmentInfo();
            console.log("here");
        }
        setData({ ...data });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.shipment_id, data.submit_type]);

    useEffect(() => {
        let fields = [
            {
                id: "submit_type",
                placeholder: "هتعمل ايه دلوقتي",
                type: "select",
                options: submitTypes,
            },
        ];
        if (data.submit_type) {
            if (data.submit_type && data.submit_type !== "store") {
                fields = [
                    ...fields,
                    {
                        id: "shipment_id",
                        placeholder: "اختر الشحنة",
                        type: "select",
                        options: shipments,
                    },
                ];

                if (data.submit_type === "update") {
                    if (data.shipment_id > 0) {
                        fields = [
                            ...fields,

                            {
                                id: "name",
                                placeholder: "اسم الشحنة",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                            {
                                id: "comment",
                                placeholder: "ملاحظة للشحنة",
                                type: "text",
                                icon: <InputIcon icon="clarity:note-edit-line" />,
                            },
                            {
                                id: "shipment_code",
                                placeholder: "كود الشحنة",
                                icon: <InputIcon icon="lets-icons:package-car" />,
                            },
                            {
                                id: "total_quantity",
                                placeholder: "العدد المستلم",
                                type: "number",
                                icon: <InputIcon icon="fluent-mdl2:quantity" />,
                            },
                            {
                                id: "arrival_time",
                                placeholder: "وقت الوصول",
                                type: "datetime",
                                icon: <InputIcon icon="ic:twotone-share-arrival-time" />,
                            },
                        ];
                    }
                }
            }
            if (data.submit_type && data.submit_type === "store") {
                fields = [
                    ...fields,

                    {
                        id: "name",
                        placeholder: "اسم الشحنة",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },
                    {
                        id: "comment",
                        placeholder: "ملاحظة للشحنة",
                        type: "text",
                        icon: <InputIcon icon="clarity:note-edit-line" />,
                    },
                    {
                        id: "shipment_code",
                        placeholder: "كود الشحنة",
                        icon: <InputIcon icon="lets-icons:package-car" />,
                    },

                    {
                        id: "total_quantity",
                        placeholder: "العدد المستلم",
                        type: "number",
                        icon: <InputIcon icon="fluent-mdl2:quantity" />,
                    },
                    {
                        id: "arrival_time",
                        placeholder: "وقت الوصول",
                        type: "datetime",
                        icon: <InputIcon icon="ic:twotone-share-arrival-time" />,
                    },
                ];
            }
        }
        setInputFields(fields);
        // console.log(`shipment:${shipments}`);
    }, [data.shipment_id, data.submit_type, shipments]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);
            const crudApiEndPoint = "/api/shipments";
            const crudItemName = "الشحنة";

            handleCrud(
                config,
                data,
                crudApiEndPoint,
                crudItemName,
                setIsLoading,
                setErrors,
                setData,
                initialState,
                data.shipment_id
            );
        });
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="حفظ" // Adjust your button text as needed
            >
                {/* Render input fields dynamically */}
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default ShipmentEditing;
