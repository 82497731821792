import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
    loginPageColor,
    outletPath,
    registerPageColor,
    storeRegisterPageIcon,
} from "../services/defaultSettings";

import AuthContext from "../context/AuthContext";

import auth from "../services/authServices";
import http from "../services/httpServices";
import modal from "../services/modalServices";
import page from "../services/pageServices";
import {
    getFormData,
    handleFormErrors,
    handleFormSubmit,
    handleInputChange,
} from "../services/formServices";

import SigningForm from "../components/form/singingForm/SigningForm";
import InputField from "../components/form/elements/InputField";
import InputIcon from "../components/form/elements/InputIcon";

import storeRegisterPicture from "../assets/storeRegister.png";
import a from "../services/analyticsServices";

const StoreRegister = () => {
    const [governments, setGovernments] = useState([]);
    const [cities, setCities] = useState([]);
    const { setUser: authUser } = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { setAccountRequest } = useContext(AuthContext);

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        school_name: "",
        phone: "",
        father_phone: "",
        government_id: 1,
        city: 0,
        address: "",
        address_link: "",
        national_id_copy: "",
        personal_picture: "",
        social_media_link: "",
        password: "",
        password_confirmation: "",
    });
    async function getCities() {
        const { data: response } = await http.get(`/api/cities/${user.government_id}`);
        setCities(response);
    }
    useEffect(() => {
        if (user.government_id && user.government_id > 0) {
            getCities();
        }
    }, [user.government_id]);

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        const filteredData = data.filter(
            (government) => !["North Sinai", "South Sinai"].includes(government.text)
        );
        setGovernments(filteredData);
    }

    useEffect(() => {
        getGovernments();
        const currentTitle = page.getCurrentTitle();
        page.setTitle(" انشاء حساب للمنفذ");
        return () => {
            page.setTitle(currentTitle);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let inputFields = [
        {
            id: "school_name",
            placeholder: `أسم منفذ البيع`,
            icon: <InputIcon icon="icon-park-solid:edit-name" />,
            className: "lg:col-span-2",
        },
        {
            id: "first_name",
            placeholder: `الاسم الأول للمسؤول`,
            icon: <InputIcon icon="icon-park-solid:edit-name" />,
        },
        {
            id: "last_name",
            placeholder: `الاسم الأخير للمسؤول`,
            icon: <InputIcon icon="icon-park-solid:edit-name" />,
        },
        {
            id: "phone",
            placeholder: "رقم هاتف المسؤول",
            icon: <InputIcon icon="ant-design:phone-filled" />,
            className: "lg:col-span-1",
        },
        {
            id: "father_phone",
            placeholder: "رقم الهاتف البديل",
            icon: <InputIcon icon="ant-design:phone-filled" />,
        },
        {
            id: "government_id",
            placeholder: "اختر محافظتك",
            type: "select",
            options: governments,

            // className: "lg:col-span-2",
        },
        {
            id: "city",
            placeholder: "اختر منطقتك",
            type: "select",
            options: cities,

            // className: "lg:col-span-1",
        },
        {
            id: "address",
            placeholder: `العنوان`,
            icon: <InputIcon icon="mdi:address-marker-outline" />,
            className: "lg:col-span-2",
        },
    ];
    inputFields = [
        ...inputFields,

        {
            id: "national_id_copy",
            placeholder: `صورة بطاقتك الشخصية
            :`,
            type: "file",
        },
        {
            id: "personal_picture",
            placeholder: `صورة للمنفذ:`,
            type: "file",
        },
        {
            id: "address_link",
            placeholder: `  لوكيشن المنفذ علي جوجل ماب (اختياري) `,
            icon: <InputIcon icon="f7:link" />,
            className: "lg:col-span-2",
        },

        {
            id: "social_media_link",
            placeholder: ` 
             لينك وسيلة تواصل اجتماعي
            (اختياري)
             `,
            icon: <InputIcon icon="f7:link" />,
            className: "lg:col-span-2",
        },
    ];
    inputFields = [
        ...inputFields,

        {
            id: "password",
            placeholder: "كلمة السر",
            icon: <InputIcon icon="ri:lock-password-fill" />,
        },
        {
            id: "password_confirmation",
            placeholder: "تأكيد كلمة السر",
            icon: <InputIcon icon="ri:lock-password-fill" />,
        },
    ];

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, registerUser);
    };

    const navigate = useNavigate();

    const { state } = useLocation();

    const redirect = (certainPath = false) => {
        if (certainPath) {
            navigate(certainPath);
        } else {
            if (!state) {
                navigate("/home");
            } else {
                navigate(state.prevPath);
            }
        }
    };

    const registerUser = async () => {
        try {
            await http.get("/sanctum/csrf-cookie");
            const visitorVisitId = a.getVisitorVisit();
            const toSendUser = {
                ...user,
                visitor_visit_id: visitorVisitId,
                city: cities.find((city) => city.value === user.city).text,
            };
            toSendUser["phone"] = parseInt(user["phone"]);
            toSendUser["father_phone"] = parseInt(user["father_phone"]);
            const formData = getFormData(toSendUser);

            const result = await http.post("api/auth/account_creation_request", formData);
            // auth.authenticate(result.data.token, result.data.user);
            // authUser({
            //     ...result.data.user,
            // });
            setIsLoading(false);
            modal.message({
                title: "تم ارسال طلب انشاء حساب و سيتم التواصل معك خلال 48 ساعة !",
                text: "اضغط حسنًا للإستمرار",
                callback: () => {
                    setAccountRequest({
                        ...result.data.user,
                    });
                    redirect("/account_creation_status");
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
            setIsLoading(false);
        }
    };

    return (
        <SigningForm
            className="space-y-6"
            headTitle={"أنشء حساب لمنفذك الآن :"}
            headIcon={storeRegisterPageIcon}
            picture={storeRegisterPicture}
            description={"تأكد من إدخال جميع البيانات بشكل صحيح حتي يتم تأكيد حساب المنفذ الخاص بك"}
            onSubmit={handleSubmit}
            isStoreRegister={true}
            fields={
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-6">
                    {inputFields.map((input, index) => {
                        return (
                            <InputField
                                key={index}
                                onChange={handleInputChange}
                                data={user}
                                setData={setUser}
                                errors={errors}
                                {...input}
                            />
                        );
                    })}
                </div>
            }
            color={registerPageColor}
            secondaryColor={loginPageColor}
            buttonTitle="إرسال طلب إنشاء حساب !"
            isLoading={isLoading}
            altLink={`/${outletPath}/login`}
            alt="يوجد لديك حساب بالفعل؟"
            altColored={"ادخل إلى حسابك الآن !"}
        />
    );
};

export default StoreRegister;
