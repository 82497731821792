import React, { useEffect, useMemo, useState } from "react";
import { submitTypes } from "../../services/adminServices";
import auth from "../../services/authServices";
import { isCouponable, isMultiYear } from "../../services/defaultSettings";
import http from "../../services/httpServices";
import InputIcon from "../../components/form/elements/InputIcon";
import { years } from "../../services/yearSevices";
import {
    handleCrud,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import Select from "react-select";
const initialState = {
    name: "",
    year: 3,
    // departments_id: [],
    price: 0,
    visible: 1,
    sellable: 1,
    is_couponable: 1,
    submit_type: 0,
    subject_id: 0,
    choosen_year: 3,
};
const SubjectEditing = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputFields, setInputFields] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const getDepartments = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `/api/admin/years/${data.year}/departments/options`,
            config
        );
        setDepartments(response);
    };

    const getSubjects = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(
            `/api/admin/years/${data.choosen_year}/subjects/options`,
            config
        );
        setSubjects(response);
    };

    const getSubjectInfo = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/subjects/${data.subject_id}`, config);
        let result = {};
        Object.keys(response).forEach((element) => {
            if (
                [
                    "name",
                    "visible",
                    "price",
                    "year",
                    "sellable",
                    "is_couponable",
                    // "departments_id",
                ].includes(element)
            ) {
                result[element] = response[element];
            }
        });

        setData({ ...data, ...result });
    };

    useEffect(() => {
        if (data.submit_type && data.submit_type !== "store") {
            getSubjects();
        }
    }, [data.choosen_year, data.submit_type]);

    useEffect(() => {
        console.log("dsa");
        getDepartments();
    }, [data.year, data.submit_type]);

    useEffect(() => {
        if (data.subject_id > 0 && data.submit_type && data.submit_type !== "store") {
            getSubjectInfo();
        }
        setData({ ...data });
    }, [data.subject_id, data.submit_type, data.choosen_year]);

    useEffect(() => {
        let fields = [
            {
                id: "submit_type",
                placeholder: "هتعمل ايه دلوقتي",
                type: "select",
                options: submitTypes,
            },
        ];

        if (data.submit_type) {
            if (data.submit_type && data.submit_type !== "store") {
                if (isMultiYear) {
                    fields = [
                        ...fields,
                        {
                            id: "choosen_year",
                            placeholder: "اختر الصف الدراسي",
                            type: "select",
                            options: years,
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "subject_id",
                        placeholder: "اختر المادة",
                        type: "select",
                        options: subjects,
                    },
                ];

                if (data.submit_type === "update") {
                    if (data.subject_id > 0) {
                        fields = [
                            ...fields,
                            {
                                id: "name",
                                placeholder: "العنوان",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                            // {
                            //     id: "departments_id",
                            //     placeholder: "الشعب",
                            //     multiple: true,
                            //     options: departments,
                            //     type: "select",
                            // },
                            // {
                            //     id: "price",
                            //     placeholder: "السعر",
                            //     type: "number",
                            //     icon: <InputIcon icon="ic:twotone-price-change" />,
                            // },
                            {
                                id: "visible",
                                placeholder: "ظاهر",
                                type: "switch",
                            },
                            // {
                            //     id: "sellable",
                            //     placeholder: "قابل للبيع",
                            //     type: "switch",
                            // },
                        ];
                        if (isCouponable) {
                            fields = [
                                ...fields,
                                {
                                    id: "is_couponable",
                                    placeholder: "تفعيل الكوبونات",
                                    type: "switch",
                                },
                            ];
                        }
                    }
                }
            }

            if (data.submit_type && data.submit_type === "store") {
                if (isMultiYear) {
                    fields = [
                        ...fields,
                        {
                            id: "year",
                            placeholder: "اختر الصف الدراسي",
                            type: "select",
                            options: years,
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "name",
                        placeholder: "العنوان",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },
                    // {
                    //     id: "departments_id",
                    //     placeholder: "الشعب",
                    //     multiple: true,
                    //     options: departments,
                    //     type: "select",
                    // },
                    // {
                    //     id: "price",
                    //     placeholder: "السعر",
                    //     type: "number",
                    //     icon: <InputIcon icon="ic:twotone-price-change" />,
                    // },
                    {
                        id: "visible",
                        placeholder: "ظاهر",
                        type: "switch",
                    },
                    // {
                    //     id: "sellable",
                    //     placeholder: "قابل للبيع",
                    //     type: "switch",
                    // },
                ];
                if (isCouponable) {
                    fields = [
                        ...fields,
                        {
                            id: "is_couponable",
                            placeholder: "تفعيل الكوبونات",
                            type: "switch",
                        },
                    ];
                }
            }
        }
        setInputFields(fields);
    }, [data.submit_type, subjects, data.subject_id, data.choosen_year, data.color, departments]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);
            const crudApiEndPoint = "/api/subjects";
            const crudItemName = "المادة";

            handleCrud(
                config,
                data,
                crudApiEndPoint,
                crudItemName,
                setIsLoading,
                setErrors,
                setData,
                initialState,
                data.subject_id
            );
        });
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="auto"
                submitType={data.submit_type}
            >
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default SubjectEditing;
