import React, { createContext, useEffect, useState } from "react";
import cartService from "../services/cartServices";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const storedCart = cartService.getCart();
        return storedCart ? JSON.parse(storedCart) : [];
    });
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);

    // useEffect(() => {
    //     console.log(`Cart: ${cart}`);
    // }, [cart]);

    useEffect(() => {
        cartService.storeCart(cart);
        const totalPrice = cart.reduce((acc, product) => {
            return acc + product.price * product.quantity;
        }, 0);
        setTotalPrice(totalPrice);

        const totalQuantity = cart.reduce((total, product) => total + product.quantity, 0);
        setTotalQuantity(totalQuantity);
    }, [cart]);




    return (
        <CartContext.Provider
            value={{ cart, setCart, totalPrice, totalQuantity, setTotalQuantity }}
        >
            {children}
        </CartContext.Provider>
    );
};
