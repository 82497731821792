import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { handleFormErrors, handleFormSubmit, handleInputChange } from "../../services/formServices";
import auth from "../../services/authServices";
import modal from "../../services/modalServices";
import page from "../../services/pageServices";
import http from "../../services/httpServices";
import InputIcon from "../../components/form/elements/InputIcon";
import SigningForm from "../../components/form/singingForm/SigningForm";
import {
    isAbleChangingAdress,
    registerPageColor,
    registerPageIcon,
} from "../../services/defaultSettings";
import InputField from "../../components/form/elements/InputField";
import Form from "../../components/form/elements/Form";
import Button from "../../components/ui/Button";
import { printIdOfOptions } from "../../utils/ar";

const AddressEdit = () => {
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [governments, setGovernments] = useState([]);
    const [cities, setCities] = useState([]);
    const { setUser: authUser, user: contextUser } = useContext(AuthContext);

    const [data, setData] = useState({
        government_id: contextUser.government_id,
        city: "",
        address: contextUser.address,
        // father_phone: contextUser.father_phone,
        father_phone:
            contextUser && contextUser.father_phone
                ? (contextUser.father_phone + "").padStart(11, "0")
                : "",
    });

    async function getGovernments() {
        const { data: response } = await http.get("/api/governments");

        const filteredData = response.filter(
            (government) => !["North Sinai", "South Sinai"].includes(government.text)
        );
        setGovernments(filteredData);

        // data["government_id"] = response[0]["value"];
        if (data.government_id == 12 || data.government_id == 18) {
            const token = auth.getToken();
            setData({ ...data, government_id: null });
            authUser({ ...contextUser, ...data });
            auth.authenticate(token, {
                ...contextUser,
                government_id: null,
            });
            // modal.message({
            //     title: "رجاء اختر محافظة اخري",
            //     text: "من فضلك اختار محافظة أخري لأستكمال بيانات استلامك",
            //     icon: "warning",
            // });
        } else {
            setData(data);
        }
    }

    useEffect(() => {
        getGovernments();
    }, []);

    async function getCities() {
        const { data: response } = await http.get(`/api/cities/${data.government_id}`);
        setCities(response);
        // setData(data);
        let city = printIdOfOptions(
            response,
            contextUser.city,
            "يتم الآن التحميل",
            "لم يتم العثور على نتيجة",
            true
        );
        // console.log(city);
        setData({
            ...data,
            city,
        });
    }
    useEffect(() => {
        if (data.government_id && data.government_id > 0) {
            setData({ ...data, city: "" });
            getCities();

            if (contextUser.government_id === data.government_id) {
            }
        }
    }, [data.government_id]);

    let inputFields = [
        {
            id: "government_id",
            placeholder: "اختر محافظتك",
            type: "select",
            options: governments,

            className: "lg:col-span-2",
        },
        {
            id: "city",
            placeholder: "اختر منطقتك",
            type: "select",
            options: cities,

            className: "lg:col-span-2",
        },
        {
            id: "address",
            placeholder: `العنوان`,
            icon: <InputIcon icon="mdi:address-marker-outline" />,
        },
        {
            id: "father_phone",
            placeholder: "رقم هاتف البديل",
            icon: <InputIcon icon="ant-design:phone-filled" />,
        },
    ];

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, registerUser);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const redirect = () => {
        if (location.state?.from) {
            navigate(location.state.from);
        } else {
            navigate("/me/user");
        }
    };

    const registerUser = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            let cityKey = cities.find((city) => city.value === data.city);
            if (!cityKey) {
                setErrors({ ...errors, city: "ممكن تتأكد من اختيار المنطقه مره تانية؟" });
                setIsLoading(false);
                return;
            }
            let city = cityKey.text;
            const toSendUser = {
                ...data,
                city,
            };
            toSendUser["father_phone"] = parseInt(data["father_phone"]);
            await http.post("api/user/address", toSendUser, config);

            setIsLoading(false);
            // setUser({...})
            modal.message({
                title: "تم تنفيذ طلبك بنجاح",
                text: `تم تعديل بيانات الأستلام بنجاح`,
                callback: () => {
                    auth.authenticate(token, {
                        ...contextUser,
                        ...data,
                        city: printIdOfOptions(cities, data.city),
                    });
                    authUser({
                        ...contextUser,
                        ...data,
                        city: printIdOfOptions(cities, data.city),
                    });
                    redirect();
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    useEffect(() => {
        const currentTitle = page.getCurrentTitle();
        page.setTitle("تعديل بيانات الأستلام");
        return () => {
            page.setTitle(currentTitle);
        };
    }, []);

    return (
        <>
            {isAbleChangingAdress ? (
                <div className="w-full">
                    <Form
                        className="space-y-6 max-w-lg mx-auto"
                        // headTitle={" بيانات الأستلام :"}
                        // headIcon={registerPageIcon}
                        // picture={false}
                        // description={"عدل بيانات استلامك الخاصة بحسابك"}
                        onSubmit={handleSubmit}
                        // color={registerPageColor}
                        // buttonTitle="تغيير "
                        // isLoading={isLoading}
                    >
                        <div className=" flex flex-col space-y-8">
                            {inputFields.map((input, index) => {
                                return (
                                    <InputField
                                        key={index}
                                        onChange={handleInputChange}
                                        data={data}
                                        setData={setData}
                                        errors={errors}
                                        className="w-full"
                                        {...input}
                                    />
                                );
                            })}
                        </div>
                        <div>
                            <Button isLoading={isLoading} color="purple" className="w-full">
                                تعديل
                            </Button>
                        </div>
                    </Form>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default AddressEdit;
