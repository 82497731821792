import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import { printFullDateTime } from "../../utils/time";
import payment from "../../services/paymentServices";
import { Link, useLocation } from "react-router-dom";
import { outletOrderReviewStatuses, printGovernment } from "../../utils/ar";
import { useEffect, useState } from "react";
import http from "../../services/httpServices";
import InputField from "../../components/form/elements/InputField";
import { handleInputChange } from "../../services/formServices";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import modal2 from "../../services/modal2Services";
import modal from "../../services/modalServices";

const OrdersTable = () => {
    const initialState = {
        user_order_type: "user",

        user_id: [],
        user_name: "",
        user_school_name: "",
        phone: "",
        government_id: [],
        address: "",

        product_id: [],
        product_name: "",

        aramex_shipment_id: "",
        invoice_id: "",
        invoice_status: [],

        delivery_status: [],

        date_range: [],
        payment_time_date_range: [],
        last_updated_delivery_status_date_range: [],
    };

    const [filterData, setFilterData] = useState(initialState);
    const [isParamsLoaded, setIsParamsLoaded] = useState(false);

    const [data, setData] = useState(initialState);

    const [governments, setGovernments] = useState([]);
    const [invoiceStatuses, setInvoiceStatuses] = useState([]);
    const [deliveryStatuses, setDeleviryStatuses] = useState([]);

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }

    async function getExistsInvoicesStatuses() {
        const token = auth.getAdminToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(
            "/api/orders/get_exists_invoice_statuses_for_options",
            config
        );
        setInvoiceStatuses(data);
    }

    async function getExistsDeliveryStatuses() {
        const token = auth.getAdminToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(
            "/api/orders/get_exists_delivery_statuses_for_options",
            config
        );
        setDeleviryStatuses(data);
    }

    useEffect(() => {
        getGovernments();
        getExistsInvoicesStatuses();
        getExistsDeliveryStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [editableData, setEditableData] = useState(null);
    const [isToEditData, setIsToEditData] = useState(false);

    const handleChangeStatus = async (order, { currentTarget: input }) => {
        let outlet_order_review_status = input.value.value;
        let { id } = order;

        if (outlet_order_review_status == order.outlet_order_review_status) {
            return;
        }
        modal2.message({
            title: "هل انت متأكد؟",
            input: "text",
            inputLabel: "يرجى كتابه كلمة السر",
            inputValue: "",
            inputValidator: (value) => {
                if (!value) {
                    return "يجب عليك كتابه كلمه السر";
                }
            },
            icon: "warning",
            confirmButtonText: "تنفيذ",
            cancelButtonText: "إلغاء",
            showCancelButton: true,
            callback: async (e) => {
                if (e.isConfirmed) {
                    try {
                        let { value: password } = e;
                        const token = auth.getAdminToken();
                        const config = auth.getAdminAuthConfig(token);
                        // eslint-disable-next-line no-unused-vars
                        const { data } = await http.post(
                            `api/orders/${id}/update_outlet_order_review_status`,
                            { outlet_order_review_status, password },
                            config
                        );

                        let newData = editableData.map((row) => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    ...data,
                                };
                            } else {
                                return row;
                            }
                        });
                        setEditableData(newData);
                        setIsToEditData(true);
                    } catch (error) {
                        modal.message({
                            title: "حدث خطأ",
                            // text: data.message,
                            icon: "error",
                            button: "حسنًا",
                        });
                    }
                    modal.message({
                        title: "تم تنفيذ طلبك بنجاح",
                        // text: data.message,
                        icon: "success",
                        button: "حسنًا",
                    });
                }
            },
        });
    };
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم المشتري",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    row.user.full_name
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
            sortable: true,
            sortField: "user_id",
        },
    ];
    if (filterData.user_order_type === "outlet") {
        columns = [
            ...columns,
            {
                name: "اسم منفذ البيع",
                reorder: true,
                selector: (row) => (row.user ? row.user.school_name : ""),
                sortable: true,
                sortField: "user_id",
            },
        ];
    }
    columns = [
        ...columns,
        {
            name: "رقم هاتف المشتري",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`../user_profile/${row.user.id}`}
                    >
                        {(row.user.phone + "").padStart(11, "0")}
                    </Link>
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
            sortable: true,
            sortField: "user_id",
        },
        {
            name: "حالة الدفع",
            selector: (row) => (
                <b>
                    <span className={`invoice_status ${row.invoice_status}`}>
                        {row.invoice_status}
                    </span>
                </b>
            ),
            sortable: true,
            sortField: "invocie_status",
        },
    ];
    if (filterData.user_order_type === "outlet") {
        columns = [
            ...columns,
            {
                name: "حاله مراجعه الاوردر",
                reorder: true,
                selector: (row) => (
                    <InputField
                        id="outlet_order_review_status"
                        placeholder="حاله مراجعه الاوردر"
                        type="select"
                        options={outletOrderReviewStatuses}
                        onChange={(input) => handleChangeStatus(row, input)}
                        data={row}
                    />
                ),
                sortable: true,
                sortField: "outlet_order_review_status",
            },
        ];
    }
    if (filterData.user_order_type === "user") {
        columns = [
            ...columns,

            {
                name: "وقت الدفع",
                selector: (row) => (row.payment_time ? printFullDateTime(row.payment_time) : ""),
                sortable: true,
                sortField: "payment_time",
            },
            {
                name: "رقم شحنه ارامكس",
                selector: (row) => (row.aramex_shipment_id ? row.aramex_shipment_id : "لا يوجد"),
                sortable: true,
                sortField: "aramex_shipment_id",
            },
            {
                name: "بوليصه ارامكس",
                selector: (row) =>
                    row.aramex_label ? (
                        <div className="flex-center-both">
                            <Button
                                color="rose"
                                element="a"
                                href={row.aramex_label}
                                target="_blank"
                            >
                                البوليصه
                            </Button>
                        </div>
                    ) : (
                        "لا توجد"
                    ),
                sortable: true,
                sortField: "aramex_label",
            },
            {
                name: "حالة الشحن",
                reorder: true,
                selector: (row) => (
                    <b>
                        <span className={`delivery_status ${row.delivery_status}`}>
                            {row.delivery_status}
                        </span>
                    </b>
                ),
                sortable: true,
                sortField: "delivery_status",
            },
            {
                name: "آخر تحديث لحالة الشحن (تاريخ الشحن)",
                reorder: true,
                selector: (row) =>
                    row.last_updated_delivery_status_date_range
                        ? printFullDateTime(row.last_updated_delivery_status)
                        : "",
                sortable: true,
                sortField: "last_updated_delivery_status",
            },
            {
                name: "وقت عمل طلب التوصيل",
                reorder: true,
                selector: (row) =>
                    row.order_delivery_creation_time
                        ? printFullDateTime(row.order_delivery_creation_time)
                        : "",
                sortable: true,
                sortField: "order_delivery_creation_time",
            },
        ];
    }
    columns = [
        ...columns,

        {
            name: "اجمالي عدد المشتريات",
            selector: (row) => row.total_quantity,
            sortable: true,
            sortField: "total_quantity",
        },
    ];
    if (filterData.user_order_type === "user") {
        columns = [
            ...columns,
            {
                name: "وزن الشحنه",
                selector: (row) => row.weight,
                sortable: true,
                sortField: "weight",
            },
            {
                name: "سعر الشحن",
                selector: (row) => row.customer_delivery_price,
                sortable: true,
                sortField: "customer_delivery_price",
            },
        ];
    }

    columns = [
        ...columns,
        {
            name: "اجمالي سعر الأوردر",
            selector: (row) => row.total_price,
            sortable: true,
            sortField: "total_price",
        },
        {
            name: "المشتريات",
            selector: (row) =>
                row.products && row.products.length ? (
                    <div className="flex flex-col space-y-2">
                        {row.products.map((product, index) => (
                            <span key={index}>
                                {product.pivot.quantity} : {product.name}
                            </span>
                        ))}
                    </div>
                ) : (
                    ""
                ),
            sortable: false,
            // sortField: "total_price",
        },
    ];
    if (filterData.user_order_type === "user") {
        columns = [
            ...columns,
            {
                name: "رقم الفاتورة",
                selector: (row) => (
                    <div>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={payment.getInvoiceUrl({
                                invoice_id: row.provider_invoice_id,
                                invoice_key: row.provider_invoice_key,
                                invoice_provider: row.invoice_provider,
                            })}
                            className="underline"
                        >
                            {row.provider_invoice_id}
                        </a>
                    </div>
                ),
                sortable: true,
                sortField: "provider_invoice_id",
            },

            {
                name: "kh_salesOrderNumber",
                selector: (row) => row.kh_salesOrderNumber,
                sortable: true,
                sortField: "kh_salesOrderNumber",
            },
        ];
    }

    columns = [
        ...columns,

        {
            name: "رقم الهاتف البديل",
            selector: (row) => (row.user ? (row.user.father_phone + "").padStart(11, "0") : ""),
            sortable: true,
            sortField: "father_phone",
        },
        {
            name: "المحافظة",
            selector: (row) => printGovernment(governments, row.government_id),
            sortable: true,
            sortField: "government_id",
        },
        {
            name: "المركز",
            selector: (row) => row.city,
            reorder: true,
        },
        {
            name: "العنوان",
            selector: (row) => row.address,
            reorder: true,
        },
        {
            name: "تاريخ عمل الاوردر",
            selector: (row) => printFullDateTime(row.created_at),
            reorder: true,
            sortable: true,
            sortField: "created_at",
        },
    ];

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const newData = {};

        // Loop through query parameters and set them in newData object
        for (const [key, value] of queryParams.entries()) {
            newData[key] = value;
        }

        // Set newData to state
        setData({ ...initialState, ...newData });
        setFilterData({ ...initialState, ...newData });
        setIsParamsLoaded(true);
        // console.log(newData);
    }, [location.search]);

    return (
        <>
            <AdminContainer>
                <div className="w-full space-y-6">
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-6 w-full">
                        <InputField
                            id="user_order_type"
                            placeholder="نوع الحساب"
                            type="select"
                            options={[
                                {
                                    value: "user",
                                    text: "حساب طالب",
                                },
                                {
                                    value: "outlet",
                                    text: "حساب منفذ بيع",
                                },
                            ]}
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className={data.user_order_type === "user" ? "" : "lg:col-span-2"}
                        />
                        <InputField
                            id="government_id"
                            placeholder="المحافظة"
                            type="select"
                            multiple={true}
                            options={[
                                {
                                    value: "all",
                                    text: "الكل",
                                },
                                ...governments,
                            ]}
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                        />
                        <InputField
                            id="invoice_status"
                            placeholder="حالة الفاتورة"
                            type="select"
                            multiple={true}
                            options={[
                                {
                                    value: "all",
                                    text: "الكل",
                                },
                                ...invoiceStatuses,
                            ]}
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                        />

                        {data.user_order_type === "user" ? (
                            <InputField
                                id="delivery_status"
                                placeholder="حالة طلب التوصيل"
                                type="select"
                                multiple={true}
                                options={[
                                    {
                                        value: "all",
                                        text: "الكل",
                                    },
                                    ...deliveryStatuses,
                                ]}
                                onChange={handleInputChange}
                                data={data}
                                setData={setData}
                            />
                        ) : (
                            ""
                        )}
                        <InputField
                            id="phone"
                            placeholder="رقم هاتف المستخدم \ رقم هاتف البديل"
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className="lg:col-span-2"
                        />
                        <InputField
                            id="user_name"
                            placeholder="اسم المستخدم"
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                        />

                        <InputField
                            id="address"
                            placeholder="المدينه \ العنوان"
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                        />

                        <InputField
                            id="product_name"
                            placeholder="اسم المنتج"
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className={data.user_order_type === "user" ? "" : "lg:col-span-2"}
                        />

                        {data.user_order_type === "user" ? (
                            <InputField
                                id="invocie_id"
                                placeholder="رقم الفاتورة"
                                onChange={handleInputChange}
                                data={data}
                                setData={setData}
                            />
                        ) : (
                            ""
                        )}
                        {data.user_order_type === "user" ? (
                            <InputField
                                id="aramex_shipment_id"
                                placeholder="رقم شحنه ارامكس"
                                onChange={handleInputChange}
                                data={data}
                                setData={setData}
                            />
                        ) : (
                            ""
                        )}

                        <InputField
                            id="date_range"
                            type="date_range"
                            placeholder="اختر موعد انشاء الاوردر"
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className="lg:col-span-2"
                        />
                        {data.user_order_type === "user" ? (
                            <>
                                <InputField
                                    id="payment_time_date_range"
                                    type="date_range"
                                    placeholder="اختر موعد الدفع"
                                    onChange={handleInputChange}
                                    data={data}
                                    setData={setData}
                                    className="lg:col-span-2"
                                />
                                <InputField
                                    id="last_updated_delivery_status_date_range"
                                    type="date_range"
                                    placeholder="اختر موعد آخر تحديث في حالة الاوردر "
                                    onChange={handleInputChange}
                                    data={data}
                                    setData={setData}
                                    className="lg:col-span-2"
                                />
                            </>
                        ) : (
                            ""
                        )}
                        {data.user_order_type === "outlet" ? (
                            <InputField
                                id="user_school_name"
                                placeholder="اسم المنفذ"
                                onChange={handleInputChange}
                                data={data}
                                setData={setData}
                                className="lg:col-span-2"
                            />
                        ) : (
                            ""
                        )}

                        {/* {data.user_order_type === "outlet" ? (
                            <InputField
                                id="school_name"
                                placeholder="اسم المنفذ"
                                onChange={handleInputChange}
                                data={data}
                                setData={setData}
                            />
                        ) : (
                            ""
                        )} */}
                    </div>
                    <Button
                        className="w-full"
                        color="blue"
                        onClick={() => {
                            setFilterData(data);
                        }}
                    >
                        بحث
                    </Button>
                    {isParamsLoaded ? (
                        <RemoteTable
                            api={`/api/orders/paginate`}
                            columns={columns}
                            filterData={filterData}
                            isToEditData={isToEditData}
                            setIsToEditData={setIsToEditData}
                            editableData={editableData}
                            setEditableData={setEditableData}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </AdminContainer>
        </>
    );
};

export default OrdersTable;
