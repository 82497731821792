import { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import InputIcon from "../../components/form/elements/InputIcon";
import {
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
    handleFormErrors,
} from "../../services/formServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import { stockType } from "../../services/contentServices";
import modal from "../../services/modalServices";

const initialState = {
    product_id: 0,
    quantity: 0,
    stock_type: "",
    shipment_id: "",
    stock_record_id: 0,
    comment: "",
    is_stock_only: 0,
};

const ProductsQuantity = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [shipments, setShipments] = useState([]);

    const [inputFields, setInputFields] = useState([]);

    const getProducts = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`api/quantity/products/options`, config);
        setProducts(response);
    };

    const getShipments = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/shipments/options`, config);
        setShipments(response);
    };

    useEffect(() => {
        getShipments();
        getProducts();
    }, [data.submit_type]);

    useEffect(() => {
        let fields = [
            {
                id: "product_id",
                placeholder: "اختر المنتج",
                type: "select",
                options: products,
            },
        ];
        if (data.product_id && data.product_id > 0) {
            fields = [
                ...fields,
                {
                    id: "quantity",
                    placeholder: "عدد المنتجات المضافة",
                    type: "number",
                    icon: <InputIcon icon="fluent:app-title-24-filled" />,
                },
                {
                    id: "stock_type",
                    placeholder: "الأضافة هتبقي ايه؟",
                    type: "select",
                    options: stockType,
                    isDisabled: data.is_stock_only,
                },

                {
                    id: "comment",
                    placeholder: "ملاحظة",
                    type: "textarea",
                    icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    isDisabled: data.is_stock_only,
                },
                {
                    id: "shipment_id",
                    placeholder: "الشحنة التابع ليها المنتج",
                    type: "select",
                    options: shipments,
                },
                {
                    id: "is_stock_only",
                    placeholder: "تعديل الأستوك فقط!",
                    type: "switch",
                    icon: <InputIcon icon="fluent:app-title-24-filled" />,
                },
            ];
        }
        setInputFields(fields);
    }, [data.is_stock_only, data.product_id, products, shipments]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            addStockRecord();
        });
    };
    const addStockRecord = async () => {
        try {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);

            const toSendData = { ...data };
            const { data: response } = await http.post("/api/stock_records", toSendData, config);

            modal.message({
                title: "تم تنفيذ طلبك بنجاح",
                text: response.message,

                buttons: {
                    confirm: "حفظ ومتابعة",
                    cancel: "متابعة",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        setData(data);
                    } else {
                        setData(initialState);
                    }
                },
            });

            // setData(initialState);
            setIsLoading(false);
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    return (
        <AdminContainer>
            <AdminForm onSubmit={handleSubmit} isLoading={isLoading} buttonText="حفظ">
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default ProductsQuantity;
