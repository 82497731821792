import Container from "../../components/ui/Container";
import HeaderCardsSection from "../../components/ui/HeaderCardsSection";
import { ProductSection } from "../Products/ProductSection";

const BestSellingSection = () => {
    return (
        <section className="min-h-[95] relative overflow-hidden my-10">
            <Container className="h-full relative z-10 font-the-sans pt-32 md:pt-0 pb-20 md:pb-0 space-y-10 flex flex-col items-center justify-center g-[104px] lg:g-[128px]">
                <div className="flex flex-col items-center justify-center gap-[104px] lg:gap-30">
                    <div className="flex flex-col items-center justify-center h-fit gap-10">
                        <HeaderCardsSection
                            title="الكتب الأكثر"
                            coloredTitle="مبيعاً"
                            colorClass="text-moGreen-400"
                        />
                    </div>
                    <ProductSection
                        product_type="book"
                        api={`/api/user/products?product_type=book&limit=9`}
                        isDecoreRight={true}
                        isShowMore={true}
                        redirect_link="/bestsellings"
                    />
                    {/* { here are svg decore} */}
                </div>
            </Container>
        </section>
    );
};

export default BestSellingSection;
