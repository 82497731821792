/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [product, setProduct] = useState({});

    return (
        <ProductContext.Provider
            value={{
                product,
                setProduct,
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export default ProductContext;
