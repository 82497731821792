import { Icon } from "@iconify/react";
import Container from "../components/ui/Container";
import ProductCard from "../components/ui/ProductCard";
import Button from "../components/ui/Button";
import { useContext, useEffect, useState } from "react";
import { useCartOutlet } from "../hooks/useCartOutlet";
import { outletPath } from "../services/defaultSettings";

export const CartOutlet = () => {
    // const { cart, totalPrice, totalQuantity } = useCart();
    const { cartOutlet, totalPriceOutlet, totalQuantityOutlet } = useCartOutlet();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <section className=" h-[50vh]  relative overflow-hidden">
                <Container className="h-full flex flex-col justify-center space-y-10 relative z-10 pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="text-center  flex-center-both flex-col space-y-5">
                        <div className="flex-center-both gap-5 text-center h-8">
                            <h1 className="text-moGreen-100 font-w-bold font-h1 dark:text-moGreen-900">
                                عربة التسوق
                            </h1>

                            <div className="bg-moGreen-200 relative rounded-full p-2">
                                <Icon className="w-6 h-6 " icon="fluent-emoji:shopping-cart" />
                                {totalQuantityOutlet !== 0 && (
                                    <span className="bg-red-300 px-1 rounded-full text-black absolute bottom-0 left-0 ">
                                        {totalQuantityOutlet}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-center-both font-cairo flex-col">
                        <h2 className="text-goBlue-100 font-semibold font-h2 dark:text-goBlue-700">
                            سعر المنتجات اللي عايزها
                        </h2>
                        <div>{totalPriceOutlet} جنيهًا</div>{" "}
                    </div>
                </Container>
            </section>
            <Container className="w-full flex flex-center-both flex-col space-y-20">
                <>
                    {cartOutlet.length ? (
                        <div className="max-w-7xl flex flex-wrap flex-center-both h-fit gap-4">
                            {cartOutlet.map((product, index) => (
                                <ProductCard
                                    key={index}
                                    product={product}
                                    url=""
                                    quantity={product.quantity}
                                    showOperators={true}
                                    showDelete={true}
                                    isMegaBuyer={true}
                                    isCartOutlet={true}
                                    product_type={product.product_type}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20 bg-rose-200 dark:bg-rose-900 w-1/2 rounded-xl">
                            <span className="flex-center-both flex-col md:flex-row space-y-3 space-x-3 ">
                                <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                                    <Icon icon="bi:cart-x-fill" />
                                </span>
                                <span className="text-center">
                                    عربة الشراء بتاعتك فاضية أملاها!
                                </span>
                            </span>
                        </div>
                    )}
                </>
                {cartOutlet.length ? (
                    <div className="">
                        <Button
                            color="emerald"
                            className={`basis-1/2 flex flex-center-both flex-row-reverse gap-2 text-center text-xl rounded-2xl shadow-xl leading-10 `}
                            isLoading={isLoading}
                            element="Link"
                            to={`/${outletPath}/request_order`}
                        >
                            طلع الفاتورة دلوقتي عشان تأكد الاوردر
                            <span className="text-moGreen-200">
                                <Icon icon="fa-solid:file-invoice-dollar" />
                            </span>
                        </Button>
                    </div>
                ) : (
                    <div className="">
                        <Button
                            color="purple"
                            className={`basis-1/2 flex flex-center-both flex-row-reverse gap-2 text-center text-xl rounded-2xl shadow-xl leading-10 `}
                            element="Link"
                            to={`/${outletPath}/outlet/home`}
                        >
                            اختار الكتب دلوقتي
                        </Button>
                    </div>
                )}
            </Container>
        </>
    );
};
