import React, { useContext, useEffect } from "react";

import MainSection from "../sections/home/MainSection";

import BestSellingSection from "../sections/home/BestSellingSection";
import CTA from "../sections/home/CTA";
import MugSection from "../sections/home/MugSection";
import FilterYearSection from "../sections/home/FilterYearSection";
import MegaBuyerSection from "../sections/home/MegaBuyerSection";
import AuthContext from "../context/AuthContext";
import { outletPath } from "../services/defaultSettings";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
    const { token, outletToken } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (outletToken) {
            navigate(`/${outletPath}/outlet/home`);
        } else {
            navigate(``);
        }
    }, [outletToken]);

    return (
        <>
            <MainSection />
            <BestSellingSection />
            <CTA />
            <FilterYearSection />
            {/* <MugSection /> */}
            {!token && <MegaBuyerSection />}
        </>
    );
};

export default Home;
