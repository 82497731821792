const localCartOutlet = "cartOutlet";

const getCartOutlet = () => {
    return localStorage.getItem(localCartOutlet);
};

const storeCartOutlet = (cartOutlet) => {
    localStorage.setItem(localCartOutlet, JSON.stringify(cartOutlet));
};

const removeCartOutlet = () => {
    localStorage.removeItem(localCartOutlet);
};

const cartOutletService = {
    getCartOutlet,
    storeCartOutlet,
    removeCartOutlet,
};
export default cartOutletService;
