import { Link } from "react-router-dom";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import { getYearPlaceHolder } from "../../services/yearSevices";
import { printUnit } from "../../utils/ar";
import { printFullDateTime } from "../../utils/time";

const ProductsTable = () => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "serial",
        },
        {
            name: "اسم المنتج",
            reorder: true,
            selector: (row) => row.name,
        },
        {
            name: "sku code",
            selector: (row) => row.sku_code,
            reorder: true,
            sortable: true,
            sortField: "sku_code",
        },
        {
            name: "الوزن",
            selector: (row) => printUnit(row.weight, "جرام"),
            reorder: true,
            sortable: true,
            sortField: "weight",
        },
        {
            name: "الكمية الكلية",
            selector: (row) => row.product_total_quantity,
            reorder: true,
            sortable: true,
            sortField: "product_total_quantity",
        },
        {
            name: "في المخزن",
            selector: (row) => row.in_stock,
            reorder: true,
            sortable: true,
            sortField: "in_stock",
        },
        {
            name: "عدد اوردرات المنافذ",
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    to={`../orders?user_order_type=outlet&product_id=${row.id}`}
                >
                    {printUnit(row.outlet_total_orders_quantity, "اوردر")}
                </Link>
            ),
            reorder: true,
            sortable: true,
            sortField: "user_ordered_quantity",
        },
        {
            name: "عدد المطلوب للمنافذ",
            selector: (row) => row.outlet_ordered_quantity,
            reorder: true,
            sortable: true,
            sortField: "outlet_ordered_quantity",
        },

        {
            name: "عدد اوردرات المشترين",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    to={`../orders?product_id=${row.id}`}
                >
                    {printUnit(row.user_total_orders_quantity, "اوردر")}
                </Link>
            ),
            sortable: true,
            sortField: "user_total_orders_quantity",
        },
        {
            name: "عدد المطلوب للمشترين",
            selector: (row) => row.user_ordered_total_quantity,
            reorder: true,
            sortable: true,
            sortField: "user_ordered_total_quantity",
        },
        {
            name: "عدد الاوردرات المدفوعه للمشترين",
            reorder: true,
            selector: (row) => (
                <Link
                    className="block px-1 py-1 text-center underline"
                    to={`../orders?product_id=${row.id}&invoice_status=paid`}
                >
                    {printUnit(row.user_total_paid_orders_quantity, "اوردر")}
                </Link>
            ),
            sortable: true,
            sortField: "user_total_paid_orders_quantity",
        },
        {
            name: "عدد المدفوع",
            selector: (row) => row.paid_total_quantity,
            reorder: true,
            sortable: true,
            sortField: "paid_total_quantity",
        },
        {
            name: "الكمية غير مدفوعة",
            selector: (row) =>
                parseInt(row.user_ordered_total_quantity) - parseInt(row.paid_total_quantity),
            // reorder: true,
        },
        {
            name: "سعر المنتج",
            selector: (row) => row.price,
            reorder: true,
            sortable: true,
            sortField: "price",
        },
        {
            name: "العمولة",
            selector: (row) => parseInt(row.price) - parseInt(row.seller_price),
            reorder: true,
            sortable: true,
            sortField: "price",
        },

        {
            name: "سعر المدفوع",
            reorder: true,
            selector: (row) => row.paid_total_price,
            sortable: true,
            sortField: "paid_total_price",
        },
        {
            name: "عائد البائع حسب المدفوع",
            reorder: true,
            selector: (row) => row.paid_total_seller_price,
            sortable: true,
            sortField: "paid_total_seller_price",
        },

        {
            name: "العام الدراسي",
            reorder: true,
            selector: (row) => row.year,
            format: (row) => getYearPlaceHolder(row.year),
            sortable: true,
            sortField: "year",
        },
        {
            name: "المادة",
            reorder: true,
            selector: (row) => (row.subject ? row.subject.name : ""),
            sortable: true,
            sortField: "subject_id",
        },
        {
            name: "اسم المدرس",
            reorder: true,
            selector: (row) => (row.teacher ? row.teacher.name : ""),
            sortable: true,
            sortField: "teacher_id",
        },

        // {
        //     name: "التالف",
        //     selector: (row) => row.total_damaged,
        //     reorder: true,
        //     sortable: true,
        //     sortField: "total_damaged",
        // },
        // {
        //     name: "بدل راجع",
        //     selector: (row) => row.total_rto,
        //     reorder: true,
        //     sortable: true,
        //     sortField: "total_rto",
        // },
        // {
        //     name: "الكمية الحقيقية",
        //     selector: (row) => row.total_inbound,
        //     reorder: true,
        //     sortable: true,
        //     sortField: "total_inbound",
        // },
        // {
        //     name: "الزيادة الآمنة",
        //     selector: (row) => row.total_safe_add,
        //     reorder: true,
        //     sortable: true,
        //     sortField: "total_safe_add",
        // },
        // {
        //     name: "عدد الواصل",
        //     selector: (row) => row.delivered_quantity,
        //     reorder: true,
        //     sortable: true,
        //     sortField: "delivered_quantity",
        // },
        // {
        //     name: "عدد الراجع",
        //     selector: (row) => row.returned_quantity,
        //     reorder: true,
        //     sortable: true,
        //     sortField: "returned_quantity",
        // },

        // {
        //     name: "إجمالي العمولة",
        //     selector: (row) => row.total_fees,
        //     reorder: true,
        // },
        // {
        //     name: "سعر المطلوب",
        //     reorder: true,
        //     selector: (row) => row.ordered_price,
        //     sortable: true,
        //     sortField: "ordered_price",
        // },
        // {
        //     name: "سعر الكمية الغير المدفوع",
        //     reorder: true,
        //     selector: (row) => parseInt(row.ordered_price) - parseInt(row.paid_price),
        // },
        // {
        //     name: "سعر الواصل",
        //     reorder: true,
        //     selector: (row) => row.delivered_price,
        //     sortable: true,
        //     sortField: "delivered_price",
        // },
        // {
        //     name: "سعر الراجع",
        //     reorder: true,
        //     selector: (row) => row.returned_price,
        //     sortable: true,
        //     sortField: "returned_price",
        // },
        {
            name: "تاريخ الاضافة",
            selector: (row) => printFullDateTime(row.created_at),
            reorder: true,
            sortable: true,
            sortField: "created_at",
        },
    ];

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <RemoteTable api={`/api/products/paginate`} columns={columns} />
            </div>
        </AdminContainer>
    );
};

export default ProductsTable;
