import React from "react";
import { Navigate } from "react-router-dom";
import auth from "../services/authServices";
import { outletPath } from "../services/defaultSettings";

const OutletRoute = ({ children, replace = true }) => {
    const outletToken = auth.getOutletToken() ? true : false;

    return outletToken ? (
        <>{children}</>
    ) : (
        <>
            <Navigate to={`/${outletPath + "/login"}`} replace={replace} />
        </>
    );
};

export default OutletRoute;
