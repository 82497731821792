/* eslint-disable react/prop-types */

import { useEffect, useState } from "react";
// import { products } from "../../Constants/DummtData";
import { Abstract, ShowMore } from "../../Constants/Svgs";
import Button from "../../components/ui/Button";
import Container from "../../components/ui/Container";
import ProductCard from "../../components/ui/ProductCard";
import LoadingIcon from "../../components/ui/LoadingIcon";
import http from "../../services/httpServices";
import auth from "../../services/authServices";
import { Icon } from "@iconify/react";

export const ProductSection = ({
    api = ``,
    product_type,
    redirect_link,
    isDecoreRight = false,
    isShowMore,
    CardColor,
    limited = true,
    isMegaBuyer,
    noProductsPlaceHolder = "سيتم إضافة المنتجات قريباً!",
    isOutlet = false,
}) => {
    const [products, setProducts] = useState([]);
    const [limitedProducts, setLimitedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // product type will define which api route will get the product from
    // console.log(products);
    const [placeholder, setPlaceholder] = useState(
        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
            <span className="flex-center-both space-x-3 space-x-reverse">
                <LoadingIcon className={"font-h1 text-teal-600 dark:text-teal-400"} />
                <span>يتم الآن تحميل المنتجات</span>
            </span>
        </div>
    );

    const getProducts = async () => {
        try {
            setIsLoading(true);

            let token = auth.getToken();
            if (isOutlet) {
                token = auth.getOutletToken();
            }
            const config = auth.getAuthConfig(token);

            const link = api;
            const { data } = await http.get(link, config);

            setProducts([]);
            setProducts(data);
            if (data.length < 1) {
                setPlaceholder(
                    <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                        <span className="flex-center-both space-x-3 space-x-reverse">
                            <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                                <Icon icon="line-md:coffee-half-empty-twotone-loop" />
                            </span>
                            <span>{noProductsPlaceHolder}</span>
                        </span>
                    </div>
                );
            }

            setIsLoading(false);
        } catch (error) {
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                            <Icon icon="line-md:coffee-half-empty-twotone-loop" />
                        </span>
                        <span>{noProductsPlaceHolder}</span>
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        const limitProducts = () => {
            let filteredProducts = products.filter(
                (product) => product.product_type === product_type
            );
            if (limited) {
                filteredProducts = filteredProducts.slice(0, 4);
            }
            setLimitedProducts(filteredProducts);
        };
        limitProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, limited]);

    // console.log(limitedProducts);
    useEffect(() => {
        if (api.length) {
            getProducts();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);
    return (
        <>
            <Container className="flex flex-center-both w-full ">
                {/* {am gonna using mapping here get first 4 bestselling course} */}
                <div className="relative w-fit flex-col  space-y-10 flex h-auto py-5 flex-center-both ">
                    {products.length < 1 && placeholder}
                    {products.length > 0 && (
                        <>
                            <div
                                className={`flex flex-wrap flex-center-both gap-5 ${
                                    !limited && `max-w-7xl`
                                }`}
                            >
                                {/* we gonna mapping here */}
                                {limitedProducts.map((product, index) => (
                                    <div key={index}>
                                        <ProductCard
                                            url={""}
                                            colorCard={`${CardColor}  dark:text-black`}
                                            product={product}
                                            showDelete={false}
                                            product_type={product_type}
                                            isMegaBuyer={isMegaBuyer}
                                        />
                                    </div>
                                ))}
                            </div>
                            {isShowMore && (
                                <Button
                                    element="Link"
                                    to={`${redirect_link}`}
                                    color=""
                                    className="bg-[#F4DCDC] flex flex-center-both hover:scale-110 hover:shadow-lg dark:bg-[#99AFFF] rounded-tl-2xl rounded-br-2xl rounded-tr-lg rounded-bl-lg font-cairo text-2xl hover:text-moGreen-200 smooth text-black"
                                >
                                    تصفح المزيد
                                    <img
                                        src={ShowMore}
                                        className="mx-2 hover:rotate-180 smooth"
                                        alt=""
                                    />
                                </Button>
                            )}
                        </>
                    )}

                    <span
                        className={`absolute  -z-10 ${
                            isDecoreRight ? "-right-[11rem]" : "-left-[11rem]"
                        } ${!products.length < 1 ? `top-1/4` : `top-0`}`}
                    >
                        <img src={Abstract} className="object-cover" alt="" />
                    </span>
                </div>
            </Container>
        </>
    );
};
