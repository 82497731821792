import Container from "../../components/ui/Container";

import ProductInfoSection from "../../sections/Products/ProductInfoSection";

const ProductHome = () => {
    return (
        <Container className="relative py-0">
            <div className="flex flex-col md:flex-row-reverse space-y-10 md:space-y-0 md:space-x-10">
                <div className=" basis-full relative ">
                    <ProductInfoSection />
                </div>
            </div>
        </Container>
    );
};

export default ProductHome;
