import { Outlet } from "react-router-dom";
import Container from "../components/ui/Container";
import HeaderCardsSection from "../components/ui/HeaderCardsSection";
import HeroSection from "../sections/home/MainSection";
import { ProductSection } from "../sections/Products/ProductSection";

const MegaBuyer = () => {
    return (
        <Container>
            {/* <HeroSection isMegaBuyer={true} /> */}

            <Outlet />
        </Container>
    );
};

export default MegaBuyer;
