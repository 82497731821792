import { Outlet, useNavigate, useParams } from "react-router-dom";
import auth from "../services/authServices";
import { useProduct } from "../hooks/useProduct";
import { useContext, useEffect } from "react";
import { isObjectEmpty } from "../utils/objects";
import page from "../services/pageServices";
import Container from "../components/ui/Container";
import LoadingIcon from "../components/ui/LoadingIcon";
import { motion } from "framer-motion";
import http from "../services/httpServices";
import { outletPath } from "../services/defaultSettings";
import AuthContext from "../context/AuthContext";

const Product = () => {
    const { product, setProduct } = useProduct();
    const navigate = useNavigate();
    const { id } = useParams();

    const { outletToken } = useContext(AuthContext);

    useEffect(() => {
        if (outletToken) {
            navigate(`/${outletPath}/outlet/home`);
        } else {
            navigate(``);
        }
    }, [outletToken]);

    const getProduct = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        try {
            const { data } = await http.get(`/api/user/products/${id}`, config);
            setProduct({});
            setProduct(data);
            // console.log(product);
        } catch (error) {
            navigate("/404-not-found");
        }
    };

    useEffect(() => {
        getProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentTitle = page.getCurrentTitle();
        let title = !isObjectEmpty(product) ? "صفحة المنتج : " + product.name : "صفحة المنتج";
        page.setTitle(title);
        return () => {
            page.setTitle(currentTitle);
        };
    }, [product]);

    return (
        <>
            <div>
                <Container className="py-0">
                    {isObjectEmpty(product) && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <div className="relative z-10 negative-nav-margin grow w-full flex-center-both font-h2 col-span-1 md:col-span-2 lg:col-span-3">
                                <span className="flex-center-both space-x-3 space-x-reverse bg-teal-500 px-28 pb-12 clr-white rounded-2xl">
                                    <LoadingIcon
                                        className={"font-big text-teal-800 dark:text-teal-400"}
                                    />
                                    <span>يتم الآن تحميل بيانات المنتج.....</span>
                                </span>
                            </div>
                        </motion.div>
                    )}
                    {!isObjectEmpty(product) && <Outlet />}
                </Container>
            </div>
        </>
    );
};

export default Product;
