import Container from "../components/ui/Container";
import HeaderCardsSection from "../components/ui/HeaderCardsSection";
import HeroSection from "../sections/home/MainSection";
import { ProductSection } from "../sections/Products/ProductSection";

const BestSellings = () => {
    return (
        <Container>
            <HeroSection isBestSellings={true} />

            <div className="flex flex-col items-center justify-center gap-[104px] lg:gap-30">
                <div className="flex flex-col items-center justify-center h-fit gap-10">
                    <HeaderCardsSection
                        title="الكتب الأكثر"
                        coloredTitle="مبيعاً"
                        colorClass="text-moGreen-400"
                    />
                </div>
                <ProductSection
                    product_type="book"
                    api={`/api/user/products?product_type=book`}
                    isDecoreRight={true}
                    isShowMore={false}
                    limited={false}
                />
                {/* { here are svg decore} */}
            </div>
        </Container>
    );
};

export default BestSellings;
