import React, { useEffect, useState } from "react";
import page from "../../services/pageServices";
import http from "../../services/httpServices";
import Button from "./Button";
import { Abstract } from "../../Constants/Svgs";
import { getYearPlaceHolder } from "../../services/yearSevices";
import { printUnit } from "../../utils/ar";
import { Link } from "react-router-dom";
const ProfileInfoCard = ({
    className,
    title,
    isForDelivery = false,
    user,
    isForInvoice = false,
    isAdmin = false,
}) => {
    const [governments, setGovernments] = useState([]);
    const [government, setGovernment] = useState({ value: 0, text: "suez" });

    async function getGovernments() {
        const { data } = await http.get("/api/governments");
        setGovernments(data);
    }

    useEffect(() => {
        if (isForDelivery) {
            getGovernments();
        }
    }, [isForDelivery]);

    useEffect(() => {
        const findGovernmentById = (governmentId) => {
            return governments.find((gov) => gov.value === governmentId);
        };
        const filterGovernment = findGovernmentById(user.government_id);
        setGovernment(filterGovernment);
    }, [governments, user.government_id]);

    return (
        <div
            className={`${className} min-h-[400px] px-5 py-2 flex max-w-sm gap-5 items-center flex-col rounded-lg `}
        >
            <h2 className="text-2xl font-bold text-moGreen-100 py-4 dark:text-moGreen-700 text-center">
                {title}
            </h2>
            <div className="flex justify-evenly w-full">
                <img src={Abstract} className="w-8" alt="" />
                <img src={Abstract} className="w-8" alt="" />
                <img src={Abstract} className="w-8" alt="" />
            </div>
            {!isForDelivery ? (
                <div className="flex w-full flex-col space-y-4 py-4">
                    {isAdmin && user.type === "outlet" ? (
                        <>
                            <span className="bg-blue-500 flex-center-both text-center rounded-md text-white pt-1 pb-1.5 font-w-bold">
                                حساب منفذ بيع
                            </span>
                        </>
                    ) : (
                        " "
                    )}
                    <p>
                        الأسم :<span className="font-w-bold">{user.full_name} </span>
                    </p>
                    <p>
                        رقم الهاتف :
                        <span className="font-w-bold">{(user.phone + "").padStart(11, "0")} </span>
                    </p>
                    <p>
                        العام الدراسي :
                        <span className="font-w-bold">{getYearPlaceHolder(user.year)}</span>
                    </p>
                    {isAdmin && user.orders_count ? (
                        <p>
                            عدد الاوردرات :
                            <span className="font-w-bold">
                                {
                                    <Link
                                        className="block px-1 py-1 text-center underline"
                                        to={`../../orders?user_id=${user.id}${
                                            user.type === "outlet" ? "&user_order_type=outlet" : ""
                                        }`}
                                    >
                                        {printUnit(user.orders_count, "اوردر")}
                                    </Link>
                                }
                            </span>
                        </p>
                    ) : (
                        ""
                    )}
                    {isAdmin && user.paid_orders_count ? (
                        <p>
                            عدد الاوردرات المدفوعه :
                            <span className="font-w-bold">
                                {
                                    <Link
                                        className="block px-1 py-1 text-center underline"
                                        to={`../../orders?user_id=${user.id}&invoice_status=paid${
                                            user.type === "outlet" ? "&user_order_type=outlet" : ""
                                        }`}
                                    >
                                        {printUnit(user.paid_orders_count, "اوردر")}
                                    </Link>
                                }
                            </span>
                        </p>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                <div className="flex flex-col w-full space-y-4 py-4">
                    <p>
                        المحافظة :<span className="font-w-bold">{government?.text} </span>
                    </p>
                    <p>
                        المركز :<span className="font-w-bold">{user.city}</span>
                    </p>
                    <p>
                        العنوان :<span className="font-w-bold">{user.address} </span>
                    </p>
                    <p>
                        الرقم البديل :
                        <span className="font-w-bold">
                            {user.father_phone ? (user.father_phone + "").padStart(11, "0") : ""}{" "}
                        </span>
                    </p>

                    <Button
                        className="rounded flex w-full hover:shadow-lg flex-center-both"
                        element="Link"
                        to={`${isForInvoice ? `/me/user/my-address` : `./my-address`}`}
                        color="indigo"
                    >
                        تعديل أو اضافة بيانات الأستلام
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ProfileInfoCard;
