import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useCart } from "../hooks/useCart";

const CartHasProducts = ({ children, replace = true }) => {
    const { cart } = useCart();
    let location = useLocation();

    // Check if the cart is empty
    const isCartEmpty = cart.length === 0;

    return isCartEmpty ? (
        <Navigate to="/" state={{ prevPath: location.pathname }} replace={replace} />
    ) : (
        <>{children}</>
    );
};

export default CartHasProducts;
