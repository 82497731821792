import { useEffect, useState } from "react";

const Header = ({ title, coloredTitle, textColor }) => {
    const [animationStarted, setAnimationStarted] = useState(false);
    useEffect(() => {
        const animationTimeout = setTimeout(() => {
            setAnimationStarted(true);
        }, 500);

        return () => clearTimeout(animationTimeout);
    }, []);
    return (
        <div className="relative min-h-[120px]">
            <h1 className="smooth text-3xl md:text-5xl">
                {title} <span className={`${textColor}`}>{coloredTitle}</span>
            </h1>
            <span className="smooth absolute bottom-16 md:bottom-8 -right-5 md:-right-8">
                <svg
                    className="w-[200px] md:w-full h-auto"
                    viewBox="0 0 395 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        className={animationStarted ? "animated-path" : ""}
                        d="M1.00006 49.0001C71.0001 59.0001 527 105.5 354 2"
                        stroke="#99AFFF"
                        strokeWidth="3"
                    />
                </svg>
            </span>
        </div>
    );
};

export default Header;
