import React, { useEffect, useState } from "react";
import HeaderCardsSection from "../../components/ui/HeaderCardsSection";
import Container from "../../components/ui/Container";
import Categorie from "../../sections/home/CoursesSection/Categorie";
import OrderCard from "../../components/ui/OrderCard";
import LoadingIcon from "../../components/ui/LoadingIcon";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import { Icon } from "@iconify/react";

const MyOrders = ({ noOrdersPlaceholder = "لم تقم بطلب أي أوردر حتي الأن!" }) => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [placeholder, setPlaceholder] = useState(
        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
            <span className="flex-center-both space-x-3 space-x-reverse">
                <LoadingIcon className={"font-h1 text-teal-600 dark:text-teal-400"} />
                <span>يتم الآن تحميل أوردراتك</span>
            </span>
        </div>
    );

    const getOrders = async () => {
        try {
            setIsLoading(true);

            const token = auth.getToken();
            const config = auth.getAuthConfig(token);

            const { data } = await http.get(`/api/orders/get-orders`, config);

            setOrders([]);
            setOrders(data);
            if (data.length < 1) {
                setPlaceholder(
                    <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                        <span className="flex-center-both space-x-3 space-x-reverse">
                            <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                                <Icon icon="carbon:data-error" />
                            </span>
                            <span>{noOrdersPlaceholder}</span>
                        </span>
                    </div>
                );
            }
            setIsLoading(false);
        } catch (error) {
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-blue-600 dark:text-blue-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noOrdersPlaceholder}</span>
                    </span>
                </div>
            );
        }
    };
    useEffect(() => {
        getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <section className="flex flex-col flex-center-both space-y-10 px-10">
                <HeaderCardsSection
                    title="أخر"
                    coloredTitle=" أوردراتك"
                    colorClass="text-goBlue-600"
                />
                <p className="w-full md:w-2/3  text-xl text-center text-slate-700 dark:text-slate-100">
                    لو عايز تتابع كل أوردراتك من هنا وتقدر تتبع حالة التوصيل الخاص بأوردراتك
                </p>
            </section>

            <Container>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 smooth clr-text-primary">
                    <>
                        {orders.length < 1 && placeholder}
                        {orders.length > 0 &&
                            orders.map((order) => <OrderCard key={order.id} {...order} />)}
                        {/* <OrderCard /> */}
                    </>
                </div>
            </Container>
        </>
    );
};

export default MyOrders;
