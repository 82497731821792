// import { isObjectEmpty } from "../../utils/objects";
import auth from "../../services/authServices";
// import { isPrepaidSystem } from "../../services/defaultSettings";
// import http from "../../services/httpServices";
import { useProduct } from "../../hooks/useProduct";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";
import Swal from "sweetalert2";
import { useCart } from "../../hooks/useCart";
import modal from "../../services/modalServices";
import TestCard from "../../assets/testCard.png";
// import { PriceSvg } from "../../Constants/Svgs";
import Header from "../../components/ui/Header";
import AuthContext from "../../context/AuthContext";
import { baseURL } from "../../config";
import http from "../../services/httpServices";

const ProductInfoSection = () => {
    const { cart, setCart, totalPrice, totalQuantity } = useCart();
    const { product } = useProduct();
    const [productQuantity, setProductQuantity] = useState(product.quantity || 0);
    const [isLoading, setIsLoading] = useState(false);

    const { token } = useContext(AuthContext);

    const navigate = useNavigate();

    const AddToCart = async (quantity, operation) => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        const toSendData = {
            cart: cart,
            total_price: totalPrice,
            total_quantity: totalQuantity,
            current_quantity: quantity,
        };
        try {
            const { data } = await http.post(`/api/add-to-cart/${product.id}`, toSendData, config);
            if (data && data.cart) {
                if (cart.length === 0) {
                    // If the local cart is empty, set it to the data.cart directly
                    setCart(data.cart);
                    modal.message({
                        title: "عملية ناجحة",
                        text: "تمت إضافة المنتج بنجاح إلى السلة",
                        icon: "success",
                    });
                } else {
                    if (operation === "plus") {
                        const existingProductIndex = data.cart.findIndex(
                            (item) => item.id === product.id
                        );

                        if (existingProductIndex !== -1) {
                            const updatedCart = [...data.cart];
                            updatedCart[existingProductIndex].quantity++;
                            setCart(updatedCart);
                        } else {
                            setCart([...data.cart, { ...product, quantity: 1 }]);
                        }
                        setProductQuantity(productQuantity + 1);

                        modal.message({
                            title: "عملية ناجحة",
                            text: "تمت إضافة المنتج بنجاح إلى السلة",
                            icon: "success",
                        });
                    } else {
                        const existingProductIndex = data.cart.findIndex(
                            (item) => item.id === product.id
                        );
                        if (existingProductIndex !== -1) {
                            const updatedCart = [...data.cart];
                            updatedCart[existingProductIndex].quantity--;
                            if (updatedCart[existingProductIndex].quantity === 0) {
                                updatedCart.splice(existingProductIndex, 1);
                            }
                            setCart(updatedCart);
                        }
                        setProductQuantity(productQuantity - 1);
                        modal.message({
                            title: "عملية ناجحة",
                            text: "تمت إزالة المنتج بنجاح من السلة",
                            icon: "success",
                        });
                    }
                }
            }
        } catch (error) {
            const message = error.response.data.message;
            modal.message({
                title: "حدث خطأ",
                text: message,
                icon: "error",
            });
        }
    };

    const handleIncrement = async (e) => {
        e.preventDefault();
        if (token) {
            await AddToCart(1, "plus");
        } else {
            modal.message({
                title: `يجب عليك تسجيل الدخول اولًا`,
                text: "يرجى تسجيل الدخول لإضافة المنتج إلى السلة",
                icon: "warning",
                button: "تسجيل الدخول",
                callback: (accept) => {
                    if (accept) {
                        navigate("/login", {
                            state: { prevPath: location.pathname },
                        });
                    }
                },
            });
        }
    };

    let location = useLocation();
    return (
        <section className="min-h-screen md:min-h-0 flex flex-center-both flex-col-reverse md:flex-row md:space-x-5 md:space-x-reverse md:py-0 py-10">
            <div className="basis-full md:basis-1/2 flex px-10 md:px-0 flex-col space-y-10 md:space-y-5 ">
                <div className="flex flex-col space-y-5 md:space-y-10">
                    <h1 className="font-h1 font-bold">
                        <Header title={product.name} textColor="text-secBlue-600" />
                    </h1>
                    {product.is_above_5_free_order_delivery ? (
                        <div className="flex justify-start">
                            <div class="font-w-bold font-small text-center bg-teal-600 text-white px-5 pt-1 pb-1.5 rounded-lg max-w-[300px]">
                                شحن مجاني على الاوردر لو طلبت خمس كتب أو أكتر من الكتاب ده !
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <p className="text-lg sm:text-xl lg:w-full md:w-3/4  ">{product.content}</p>
                </div>
                <div className="font-h1 ">
                    <div className="relative w-full h-fit flex  gap-3 ">
                        <div className="mozkra-card__price flex space-x-4  text-center">
                            <div className="font-bold font-Noto flex space-x-2">
                                <div className=" z-10 text-moGreen-100 dark:text-secBlue-700 ">
                                    {" "}
                                    السعر:
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className="font-bold font-Noto flex gap-1 space-x-2">
                                <span>{product.price}</span>
                                <span className="text-secBlue-800 dark:text-secBlue-900 ">
                                    جنيهًا
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-center-both ">
                    {!product.in_stock ? (
                        <>
                            <div className="col-12 px-2">
                                <div className="py-1 px-3 w-100 bg-rose-900 clr-white my-3 text-center rounded-lg">
                                    الكتاب ده خلص للأسف , هيتوفر منه كمية جديدة قريب !
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col w-full gap-0 md:gap-4 md:flex-row space-y-5 md:space-y-0">
                            <Button
                                color={token ? "indigo" : "blue"}
                                className={`basis-1/2 inline-block text-center `}
                                isLoading={token && isLoading}
                                element="Link"
                                to={token ? "/order_invoice" : "/login"}
                            >
                                {token ? "الذهاب للشراء الأن" : "تسجيل الدخول للشراء الأن"}
                            </Button>

                            <Button
                                className="basis-1/2 inline-block text-center"
                                color="emerald"
                                element="button"
                                onClick={handleIncrement}
                            >
                                إضافة لعربة التسوق
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="basis-full md:basis-1/2 h-full flex-center-both">
                <div className="flex-center-both max-w-2xl">
                    <img
                        src={product.picture ? `${baseURL}/${product.picture}` : `${TestCard}`}
                        className="object-contain"
                        alt=""
                        style={{ aspectRatio: "1/1" }}
                    />
                </div>
            </div>
        </section>
    );
};

export default ProductInfoSection;
