import Container from "../../components/ui/Container";
import Header from "../../components/ui/Header";
import HeaderCardsSection from "../../components/ui/HeaderCardsSection";
import ProfileInfoCard from "../../components/ui/ProfileInfoCard";
import { useAuth } from "../../hooks/useAuth";

const UserHomeProfile = () => {
    const { user } = useAuth();

    return (
        <>
            <Container className="h-full relative z-10 pt-32 md:pt-0 pb-20 md:pb-0 flex  flex-col space-y-10">
                <div className="flex flex-col space-y-5">
                    <h1 className=" text-4xl leading-normal">
                        أهلاً بيك يـ
                        <span className="text-secBlue-600"> {user.full_name} </span>
                    </h1>
                    <p className="w-full md:w-2/3 text-slate-700 dark:text-slate-100">
                        من هنا تقدر تتابع اخر أوردراتك وتعرف تغير بيانات الأستلام بتاعتك بكل سهولة!
                    </p>
                </div>
                <div className=" flex w-full flex-wrap md:flex-nowrap gap-5">
                    <ProfileInfoCard
                        className="bg-indigo-200 basis-full md:basis-1/2 dark:bg-indigo-900"
                        title="البيانات الشخصية"
                        user={user}
                    />

                    <ProfileInfoCard
                        className="bg-moGreen-900 basis-full md:basis-1/2 dark:bg-moGreen-200"
                        isForDelivery={true}
                        title="بيانات الاستلام"
                        user={user}
                    />
                </div>
            </Container>
        </>
    );
};

export default UserHomeProfile;
