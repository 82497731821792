import React from "react";

import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import CenterIcon from "../components/ui/CenterIcon";

// import logoTextDark from "../assets/logodark.png";
// import logoTextLight from "../assets/logolight.png";

import { SalahLogo } from "../Constants/Svgs";

export const navLogoComponent = (darkmode) => (
    <>
        {/* <img className="h-10 sm:h-14 w-auto" src={logo} alt="Workflow" /> */}
        {!darkmode ? (
            <img src={SalahLogo} className="h-10 sm:h-14 w-auto" alt="Salah-store-logo" />
        ) : (
            <img src={SalahLogo} className="h-10 sm:h-14 w-auto" alt="Salah-store-logo" />
            // <img src={LogoDark} className="h-10 sm:h-14 w-auto" alt="Salah-store-logo" />
        )}
    </>
);

export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    ` ${
        scrolling &&
        " bg-secBlue-950 dark:bg-[#1B4474] md:min-h-[90px] p-0 md:p-[10px] mx-0 md:mx-4 md:my-2 my-0 md:rounded-[22px] md:shadow-xl"
    }`;
export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover="hover:bg-primary-container hover:text-indigo-500 group"
        to="/register"
        className="bg-indigo-500 clr-white border-2 border-indigo-500 group"
    >
        <span className="flex-center-both font-h2 smooth text-indigo-300 group-hover:text-indigo-500 dark:group-hover:text-indigo-500">
            <CenterIcon icon="ph:books-duotone" nY="0" />
        </span>
        <span className="flex-center-both">أنشئ حسابك الآن !</span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="border border-moGreen-400 border-opacity-0"
        defualtHover="hover:dark:border-opacity-100  hover-shadow group"
    >
        <CenterIcon
            icon="line-md:home-twotone"
            className="text-moGreen-500 dark:text-moGreen-300 font-h1 group-hover:rotate-[-360deg] smooth"
            nY="0"
        />
        <span className="flex-center-both space-x-1 space-x-reverse">
            <span>تسجيل</span>
            <span className="text-moGreen-500 dark:text-moGreen-300">الدخول</span>
        </span>
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <CenterIcon icon="line-md:home-twotone" className="text-yellow-400 font-h1" nY="0" />

        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <CenterIcon icon="ph:books-duotone" className="font-h1 text-indigo-400" nY="0" />
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">أنشئ حسابك الآن !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";
