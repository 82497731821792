import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import auth from "../services/authServices";
// import http from "../services/httpServices";
import modal from "../services/modalServices";

import Button from "../components/ui/Button";
import CenterIcon from "../components/ui/CenterIcon";
import Container from "../components/ui/Container";
import FlexRowReverse from "../components/ui/FlexRowReverse";
import Header from "../components/ui/Header";
import { useCartOutlet } from "../hooks/useCartOutlet";
import { outletPath } from "../services/defaultSettings";
import cartOutletService from "../services/cartOutletServices";
import http from "../services/httpServices";
import auth from "../services/authServices";

const OrderOutlet = () => {
    const { cartOutlet, totalPriceOutlet, totalQuantityOutlet, setCartOutlet } = useCartOutlet();
    const [orderButtonLoading, setOrderButtonLoading] = useState(false);

    const navigate = useNavigate();

    const OrderInstruction = () => {
        modal.message({
            title: "انتبه",
            text: `اتأكد كويس من المنتجات اللي هتطلبها قبل م تدفع`,
            icon: "warning",
            buttons: {
                confirm: " تأكيد للأوردر",
                cancel: "الغاء",
            },
            callback: (e) => {
                if (e && e !== "cancel") {
                    createOutletOrderRequest();
                }
            },
        });
    };
    const createOutletOrderRequest = async () => {
        setOrderButtonLoading(true);

        try {
            const token = auth.getOutletToken();
            const config = auth.getAuthConfig(token);
            // eslint-disable-next-line no-unused-vars
            const { data: result } = await http.post(
                `/api/outlet/orders/order_request`,
                {
                    cart: cartOutlet,
                },
                config
            );
            setCartOutlet([]);

            modal.message({
                title: "تم الطلب بنجاح",
                text: `أنتظرنا لكي نتواصل معك وشحن طلبك`,
                icon: "success",
                buttons: {
                    confirm: " في الأنتظار",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        navigate(`/${outletPath}/outlet/home`);
                        cartOutletService.removeCartOutlet();
                        setCartOutlet([]);
                    }
                },
            });
        } catch ({ response }) {
            if (parseInt(response.status) === 443) {
                setCartOutlet(response.data.updated_cart);

                modal.message({
                    title: "عفواً!",
                    text: "تم تعديل عدد بعض المنتجات لعدم توافرها بالكميات المطلوبه",
                    icon: "warning",
                });
                return;
            }
            modal.message({
                title: "حدث خطأ اثناء انشاء الفاتورة",
                text: "يرجى الاتصال بالدعم",
                icon: "error",
            });
            setOrderButtonLoading(false);
        }
    };
    return (
        <>
            <div className="bg-outer-container smooth clr-text-primary negative-nav-margin posisitve-nav-padding-top">
                <div className="my-4 flex-center-both flex-col">
                    <div className="space-y-10 flex-col flex-center-both">
                        <h1 className="font-w-bold font-Graphik font-big">
                            <Header
                                title="مراجعة"
                                coloredTitle="الطلب"
                                textColor="text-secBlue-600"
                            />
                        </h1>

                        {}
                        <div className="flex flex-col space-y-5 flex-center-both">
                            <p className="font-h3 text-gray-700 w-3/4 text-center dark:text-gray-300 pb-3">
                                لتأكيد طلبك سوف نتواصل معاك علي الأرقام التي سجلت بها حيث يمكنك
                                الدفع لتصلك الشحنة سريعاً والأولوية بأسبقية الحجز يمكنك تأكيد طلبك
                                بالأسفل
                            </p>
                            <p className="text-center">
                                - يتم توصيل الشحنة في خلال ٤٨ ل ٧٢ ساعة للمنفذ
                                <br />
                                هيتم التواصل معاك بتأكيد شحن الطلب و وسائل الدفع
                            </p>
                            <div className="divider-1 bg-slate-800 dark:bg-slate-300 rounded-xl w-2/3 my-3" />
                        </div>
                        <div className="flex flex-center-both flex-col space-y-3 ">
                            <h2>اجمالي سعر الطلب </h2>

                            <div className="flex flex-center-both w-fit shadow-lg  bg-amber-200 dark:bg-amber-800 rounded-lg">
                                <p className="px-4">{totalPriceOutlet}</p>
                                <div className="py-1 px-3 mr-2 rounded-md bg-goBlue-800 dark:bg-goBlue-200">
                                    جنيهاً
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container>
                    <div className="relative z-10 bg-inner-container smooth clr-text-primary rounded-lg shadow-lg p-10 ">
                        <Link
                            element="Link"
                            className="underline clr-text-secondary smooth"
                            to={-1}
                        >
                            <FlexRowReverse>
                                <CenterIcon icon="akar-icons:arrow-right" />
                                <span>العودة للوراء</span>
                            </FlexRowReverse>
                        </Link>
                        <div className="font-h1 flex-center-both pb-10 font-w-bold">
                            <div className="">الفاتورة</div>
                        </div>
                        <div>
                            <table className="table-auto w-full table-style">
                                <thead className="py-10">
                                    <tr>
                                        <th className="h-20 text-center">اسم المنتج</th>
                                        <th className="h-20 text-center"> السعر</th>
                                        <th className="h-20 text-center">عدد المنتج</th>
                                        <th className="h-20 text-center">اجمالي سعر المنتج</th>
                                    </tr>
                                </thead>

                                <tbody className="py-10">
                                    {cartOutlet.map((product, index) => (
                                        <tr key={index}>
                                            <td className="h-20 text-center">{product.name}</td>
                                            <td className="h-20 text-center">
                                                {product.price} جنيهًا
                                            </td>
                                            <td className="h-20 text-center">{product.quantity}</td>
                                            <td className="h-20 text-center">
                                                {product.price * product.quantity} جنيهًا
                                            </td>
                                        </tr>
                                    ))}

                                    <tr className="bg-moGreen-900 ">
                                        <td className="h-20 text-center font-bold ">الإجمالي</td>
                                        <td className="h-20 text-center text-white">-</td>
                                        <td className="h-20 text-center"> {totalQuantityOutlet}</td>
                                        <td className="h-20 text-center font-bold">
                                            {totalPriceOutlet}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="flex-center-both pt-10">
                            <div className="w-full max-w-lg space-y-3">
                                <div className="w-full max-w-lg space-y-3">
                                    <Button
                                        color="emerald"
                                        className="w-full"
                                        onClick={OrderInstruction}
                                        isLoading={orderButtonLoading}
                                    >
                                        تأكيد الطلب
                                    </Button>

                                    <p className="font-Graphik text-lg font-bold text-center">
                                        {" "}
                                        - أو -
                                    </p>

                                    <Button
                                        color="blue"
                                        className="flex flex-center-both"
                                        element="Link"
                                        to={`/${outletPath}/cart`}
                                    >
                                        تعديل عربة التسوق
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default OrderOutlet;
