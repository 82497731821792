const tokenKey = "loginToken";
const userKey = "user";
const adminTokenKey = "adminLoginToken";
const adminKey = "admin";

const outletKey = "outlet";
const outletTokenKey = "outletLoginToken";

const prepaidSeenKey = "prepaidseen";

const getToken = () => {
    return localStorage.getItem(tokenKey);
};

const getAdminToken = () => {
    return localStorage.getItem(adminTokenKey);
};

const getOutletToken = () => {
    return localStorage.getItem(outletTokenKey);
};

const getOutlet = () => {
    try {
        const outlet = localStorage.getItem(outletKey);
        return JSON.parse(outlet);
    } catch (error) {
        return null;
    }
};

const getUser = () => {
    try {
        const user = localStorage.getItem(userKey);
        return JSON.parse(user);
    } catch (error) {
        return null;
    }
};
const getAdmin = () => {
    try {
        const admin = localStorage.getItem(adminKey);
        return JSON.parse(admin);
    } catch (error) {
        return null;
    }
};

const authenticate = (token, user) => {
    user = JSON.stringify(user);
    localStorage.setItem(userKey, user);
    return localStorage.setItem(tokenKey, token);
};

const authenticateOutlet = (outletToken, outlet) => {
    outlet = JSON.stringify(outlet);
    localStorage.setItem(outletKey, outlet);
    return localStorage.setItem(outletTokenKey, outletToken);
};
const authenticateAdmin = (adminToken, admin) => {
    admin = JSON.stringify(admin);
    localStorage.setItem(adminKey, admin);
    return localStorage.setItem(adminTokenKey, adminToken);
};

const logout = () => {
    localStorage.removeItem(userKey);
    localStorage.removeItem("cart");
    return localStorage.removeItem(tokenKey);
};

const adminLogout = () => {
    localStorage.removeItem(adminKey);
    return localStorage.removeItem(adminTokenKey);
};
const outletLogout = () => {
    localStorage.removeItem(outletKey);
    localStorage.removeItem("cartOutlet");
    return localStorage.removeItem(outletTokenKey);
};

const getAuthConfig = (token) => {
    return {
        headers: { Authorization: `Bearer ${token}` },
    };
};
const getAdminAuthConfig = (adminToken) => {
    return {
        headers: { Authorization: `Bearer ${adminToken}` },
    };
};

const isAuth = getToken() ? true : false;
const isAdmin = getAdminToken() ? true : false;

const setPrepaidCoursesStoreSeen = (value) => {
    if (value === true) {
        return localStorage.setItem(prepaidSeenKey, value);
    } else {
        return localStorage.removeItem(prepaidSeenKey);
    }
};
const getIsPrepaidCoursesStoreSeen = () => {
    return localStorage.getItem(prepaidSeenKey);
};

const auth = {
    getToken,
    getOutletToken,
    authenticate,
    logout,
    isAuth,
    authenticateOutlet,
    getUser,
    getAuthConfig,
    getAdminToken,
    authenticateAdmin,
    adminLogout,
    isAdmin,
    getAdmin,
    getOutlet,
    outletLogout,
    getAdminAuthConfig,
    setPrepaidCoursesStoreSeen,
    getIsPrepaidCoursesStoreSeen,
};
export default auth;
