import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import http from "../services/httpServices";
import auth from "../services/authServices";
import modal from "../services/modalServices";
import page from "../services/pageServices";
import InputIcon from "../components/form/elements/InputIcon";
import AuthContext from "../context/AuthContext";

import adminLogin from "../assets/admin-login.png";
import loginIcon from "../assets/gear.svg";
import SigningForm from "../components/form/singingForm/SigningForm";
import InputField from "../components/form/elements/InputField";
import { adminPath, outletPath, isAccountCreationRequest } from "../services/defaultSettings";
import { handleFormErrors, handleFormSubmit, handleInputChange } from "../services/formServices";
import a from "../services/analyticsServices";
import { CartContext } from "../context/CartContext";

const OutletLogin = () => {
    const [user, setUser] = useState({
        phone: "",
        password: "",
    });
    const [errors, setErrors] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const { setOutlet: authOutlet, setAccountRequest, setUser: authUser } = useContext(AuthContext);

    const inputFields = [
        {
            id: "phone",
            placeholder: "رقم الهاتف",
            icon: <InputIcon icon="ant-design:phone-filled" />,
        },
        {
            id: "password",
            placeholder: "كلمة السر",
            icon: <InputIcon icon="ri:lock-password-fill" />,
        },
    ];

    useEffect(() => {
        const currentTitle = page.getCurrentTitle();
        page.setTitle(" تسجيل الدخول كمنفذ");
        return () => {
            page.setTitle(currentTitle);
        };
    }, []);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, loginUser);
    };

    const navigate = useNavigate();
    const { state } = useLocation();

    const redirect = (certainPath = false) => {
        if (certainPath) {
            navigate(certainPath);
        } else {
            if (!state) {
                navigate(`/${outletPath}/outlet/home`);
            } else {
                navigate(state.prevPath);
            }
        }
    };

    const loginUser = async () => {
        try {
            await http.get("/sanctum/csrf-cookie");
            const visitorVisitId = a.getVisitorVisit();
            const toSendUser = { ...user, visitor_visit_id: visitorVisitId };
            toSendUser["phone"] = parseInt(user["phone"]);
            const result = await http.post("api/auth/outlet/login", toSendUser);

            auth.authenticateOutlet(result.data.token, result.data.user);
            authOutlet({
                ...result.data.user,
            });

            auth.logout();
            authUser({});

            setIsLoading(false);
            modal.message({
                title: "تم تسجيل الدخول بنجاح !",
                text: "اضغط حسنًا للإستمرار",

                // when it is called directly, params passes automatically, which is not desired, because it returns :
                // redirect to "true", which gives error 404 not found.
                callback: () => redirect(),
            });
        } catch ({ response }) {
            if (response.status === 455 && isAccountCreationRequest) {
                setAccountRequest(response.data.user);
                redirect("/account_creation_status");
            } else {
                handleFormErrors(response, setIsLoading, setErrors);
            }
        }
    };

    return (
        <SigningForm
            headTitle={"الدخول للموقع كمنفذ :"}
            headIcon={loginIcon}
            toSpin={true}
            picture={adminLogin}
            description={"ادخل اسم المستخدم و كلمة السر للوصول لمنفذك وطلب منتاجاتنا"}
            onSubmit={handleSubmit}
            isAdminLogin={true}
            fields={
                <div className="space-y-12">
                    {inputFields.map((input, index) => {
                        return (
                            <InputField
                                key={index}
                                onChange={handleInputChange}
                                data={user}
                                setData={setUser}
                                errors={errors}
                                {...input}
                            />
                        );
                    })}
                </div>
            }
            color={"purple"}
            secondaryColor={"blue"}
            buttonTitle="تسجيل الدخول"
            isLoading={isLoading}
            altLink={"/register"}
        />
    );
};

export default OutletLogin;
