import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import auth from "../../services/authServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";

import Button from "../../components/ui/Button";
import CenterIcon from "../../components/ui/CenterIcon";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";
import a from "../../services/analyticsServices";
import { useCart } from "../../hooks/useCart";
import Header from "../../components/ui/Header";
import { useAuth } from "../../hooks/useAuth";
import ProfileInfoCard from "../../components/ui/ProfileInfoCard";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { printUnit } from "../../utils/ar";

const OrderInvoice = () => {
    const { cart, totalPrice, totalQuantity, setCart } = useCart();
    const { user } = useAuth();
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [totalOrderPrice, setTotalOrderPrice] = useState(0);
    const [orderButtonLoading, setOrderButtonLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [orderHasFreeShipping, setOrderHasFreeShipping] = useState(false);
    async function getDeliveryPrice() {
        setIsLoading(true);
        let toSendData = {
            cart,
        };
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data: response } = await http.post(`/api/get_delivery_price`, toSendData, config);
        const price = parseFloat(response);
        setDeliveryPrice(price);
        // setTotalOrderPrice(totalPrice + price);
        setIsLoading(false);
    }
    useEffect(() => {
        getDeliveryPrice();
        setOrderHasFreeShipping(
            cart.some((product) => product.is_above_5_free_order_delivery && product.quantity >= 5)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);
    const OrderInstruction = () => {
        modal.message({
            title: "انتبه",
            text: `اتأكد كويس من المنتجات اللي هتطلبها قبل م تدفع`,
            icon: "warning",
            buttons: {
                confirm: " الذهاب للدفع",
                cancel: "الغاء",
            },
            callback: (e) => {
                if (e && e !== "cancel") {
                    createInvoice();
                }
            },
        });
    };
    const createInvoice = async () => {
        setOrderButtonLoading(true);
        let data = {};
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const visitorVisitId = a.getVisitorVisit();
        try {
            const { data: result } = await http.post(
                `/api/orders/order_request`,
                {
                    ...data,
                    cart: cart,
                    total_price: totalPrice,
                    total_quantity: totalQuantity,
                    visitor_visit_id: visitorVisitId,
                },
                config
            );
            setCart([]);
            window.location.href = result.url;
        } catch ({ response }) {
            if (parseInt(response.status) === 443) {
                setCart(response.data.updated_cart);

                modal.message({
                    title: "عفواً!",
                    text: "تم تعديل عدد بعض المنتجات لعدم توافرها بالكميات المطلوبه",
                    icon: "warning",
                });
                return;
            }
            modal.message({
                title: "حدث خطأ اثناء انشاء الفاتورة ",
                text: "يرجى الاتصال بالدعم",
                icon: "error",
            });
            setOrderButtonLoading(false);
        }
    };

    const CheckAvailabilityTotalCart = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const toSendData = { cart: cart };
        const { data } = await http.post(
            `/api/check_and_update_cart_availability`,
            toSendData,
            config
        );
        if (data.unavailableDetected) {
            setCart(data.updated_cart);

            modal.message({
                title: "عفواً!",
                text: data.error_message,
                icon: "warning",
            });
        }
    };
    useEffect(() => {
        // performing Checking cart availbality once user land on cart page
        CheckAvailabilityTotalCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    useEffect(() => {
        setTotalOrderPrice(deliveryPrice + totalPrice);
    }, [totalPrice, deliveryPrice]);
    return (
        <>
            <div className="bg-outer-container smooth clr-text-primary negative-nav-margin posisitve-nav-padding-top">
                <div className="my-4 flex-center-both flex-col">
                    <div className="space-y-10 flex-col flex-center-both">
                        <h1 className="font-w-bold font-Graphik font-big">
                            <Header
                                title="مراجعة"
                                coloredTitle="الفاتورة"
                                textColor="text-secBlue-600"
                            />
                        </h1>
                        {}
                        <div className="flex flex-col space-y-5">
                            <p className="font-h3 text-gray-700 dark:text-gray-300 pb-3">
                                راجع الفاتورة جيدًا قبل التأكيد و الذهاب للدفع
                            </p>
                            <div className="divider-1 bg-slate-800 dark:bg-slate-300 rounded-xl w-2/3 my-3" />
                        </div>
                        <div className="flex flex-center-both flex-col space-y-3 ">
                            <h2>اجمالي سعر الطلب بالتوصيل</h2>
                            {isLoading ? (
                                <div className="h-10  flex-center-both font-h1 font-w-bold">
                                    <div className="flex flex-row space-x-3 space-x-reverse">
                                        <LoadingIcon className={"font-big text-blue-500"} />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-center-both w-fit shadow-lg  bg-amber-200 dark:bg-amber-800 rounded-lg">
                                    <p className="px-4">{totalOrderPrice}</p>
                                    <div className="py-1 px-3 mr-2 rounded-md bg-goBlue-800 dark:bg-goBlue-200">
                                        جنيهاً
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="min-w-[400px]">
                            <ProfileInfoCard
                                className="bg-moGreen-900 shadow-lg  dark:bg-goBlue-100"
                                isForDelivery={true}
                                title="بيانات الاستلام"
                                user={user}
                                isForInvoice={true}
                            />
                        </div>
                    </div>
                </div>
                <Container>
                    <div className="relative z-10 bg-inner-container smooth clr-text-primary rounded-lg shadow-lg p-10 ">
                        <Link
                            element="Link"
                            className="underline clr-text-secondary smooth"
                            to={-1}
                        >
                            <FlexRowReverse>
                                <CenterIcon icon="akar-icons:arrow-right" />
                                <span>العودة للوراء</span>
                            </FlexRowReverse>
                        </Link>
                        <div className="font-h1 flex-center-both pb-10 font-w-bold">
                            <div className="">الفاتورة</div>
                        </div>
                        <div>
                            <table className="table-auto w-full table-style">
                                <thead className="py-10">
                                    <tr>
                                        <th className="h-20 text-center">اسم المنتج</th>
                                        <th className="h-20 text-center"> السعر</th>
                                        <th className="h-20 text-center">عدد المنتج</th>
                                        <th className="h-20 text-center">اجمالي سعر المنتج</th>
                                    </tr>
                                </thead>

                                <tbody className="py-10">
                                    {cart.map((product, index) => (
                                        <tr key={index}>
                                            <td className="h-20 text-center">{product.name}</td>
                                            <td className="h-20 text-center">
                                                {product.price} جنيهًا
                                            </td>
                                            <td className="h-20 text-center">{product.quantity}</td>
                                            <td className="h-20 text-center">
                                                {product.price * product.quantity} جنيهًا
                                            </td>
                                        </tr>
                                    ))}

                                    <tr className="bg-moGreen-200 ">
                                        <td className="h-20 text-center ">
                                            <span className="text-white">
                                                مصاريف الشحن{" "}
                                                {orderHasFreeShipping ? (
                                                    <>(مصاريف الوزن فقط - تم خصم سعر الشحن)</>
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                        </td>
                                        <td className="h-20 text-center text-white">
                                            <span className="text-white">
                                                {printUnit(deliveryPrice, "جنيه")}{" "}
                                            </span>
                                        </td>
                                        <td className="h-20 text-center">
                                            {" "}
                                            <span className="text-white">-</span>
                                        </td>
                                        <td className="h-20 text-center font-bold">
                                            <span className="text-white">
                                                {printUnit(deliveryPrice, "جنيه")}{" "}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className="bg-moGreen-900 ">
                                        <td className="h-20 text-center font-bold ">الإجمالي</td>
                                        <td className="h-20 text-center text-white">-</td>
                                        <td className="h-20 text-center"> {totalQuantity}</td>
                                        <td className="h-20 text-center font-bold">
                                            {printUnit(totalOrderPrice, "جنيه")}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="flex-center-both pt-10">
                            <div className="w-full max-w-lg space-y-3">
                                <Button
                                    color="emerald"
                                    className="w-full"
                                    onClick={OrderInstruction}
                                    isLoading={orderButtonLoading}
                                >
                                    الذهاب للدفع
                                </Button>

                                <p className="font-Graphik text-lg font-bold text-center">
                                    {" "}
                                    - أو -
                                </p>

                                <Button
                                    color="blue"
                                    className="flex flex-center-both"
                                    element="Link"
                                    to="/cart"
                                >
                                    تعديل عربة التسوق
                                </Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default OrderInvoice;
