import { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import InputIcon from "../../components/form/elements/InputIcon";
import {
    handleCrud,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import { submitTypes } from "../../services/adminServices";
import ShowImage from "../../components/form/elements/ShowImage";
// import { products } from "../../Constants/DummtData";
import { years, semester, ProductYears } from "../../services/yearSevices";
import { productTypes } from "../../services/contentServices";
import { isCategories, isMultiSellers, isMultiYear } from "../../services/defaultSettings";

const initialState = {
    product_id: 0,
    year: 3,
    choosen_year: 3,
    semester: 1,
    product_type: "book",
    name: "",
    description: "",
    content: "",
    sku_code: "",
    weight: 0,
    price: 0,
    seller_price: 0,
    category_id: 0,
    teacher_id: 0,
    subject_id: 0,
    is_visible: 1,
    picture: "",
    submit_type: 0,
    to_edit_picture: 0,
};

const ProductEditing = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [filteredProductTypes, setFilteredProductTypes] = useState(productTypes); // State to hold filtered product types

    const [inputFields, setInputFields] = useState([]);
    const [productPicture, setProductPicutre] = useState("");

    const [teachers, setTeachers] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [categoties, setCategoties] = useState([]);
    // const [isBook, setIsBook] = useState(false);
    const getCategories = async (teacher_id, year) => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(
            `/api/teachers/${teacher_id}/years/${year}/categories`,
            config
        );
        setCategoties(response);
    };

    const getProducts = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `api/years/${data.choosen_year}/products/options`,
            config
        );
        setProducts(response);
    };
    useEffect(() => {
        if (data.submit_type && data.submit_type !== "store") {
            getProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.submit_type, data.choosen_year]);
    const getProductInfo = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/products/${data.product_id}`, config);
        let result = {};
        Object.keys(response).forEach((element) => {
            if (
                [
                    "name",
                    "description",
                    "content",

                    "sku_code",
                    "weight",
                    "product_type",

                    "price",
                    "seller_price",

                    "year",
                    "semester",
                    "subject_id",
                    "teacher_id",
                    "is_visible",
                    "is_visible_for_outlet",
                    "is_outlet_stock_available",
                ].includes(element)
            ) {
                result[element] = response[element];
            }
        });

        setProductPicutre(response.picture);
        setData({ ...data, ...result, picture: "", to_edit_picture: 0 });
    };

    useEffect(() => {
        if (isCategories) {
            if (data.submit_type === "store") {
                setCategoties([]);
                if (data.teacher_id && data.year) {
                    getCategories(data.teacher_id, data.year);
                }
            }
            if (data.submit_type === "update") {
                setCategoties([]);
                if (data.teacher_id && data.choosen_year) {
                    getCategories(data.teacher_id, data.choosen_year);
                }
            }
        }
    }, [data.submit_type, data.teacher_id, data.year, data.choosen_year]);

    const getTeachers = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/admin/teachers/options`, config);
        setTeachers(response);
    };

    useEffect(() => {
        getTeachers();
    }, []);

    const getSubjects = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `/api/admin/withyears/teachers/${data.teacher_id}/subjects/options`,
            config
        );
        setSubjects([]);
        setSubjects(response);
    };
    useEffect(() => {
        setData({ ...data, subject_id: 0 });
        if (data.teacher_id > 0) {
            getSubjects();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.teacher_id]);
    useEffect(() => {
        if (data.year === "4") {
            // Filter out the item with text "كتاب" (assuming this is the item you want to remove)
            const filteredTypes = productTypes.filter((type) => type.value !== "book");
            setFilteredProductTypes(filteredTypes);
        } else {
            const filteredTypes = productTypes.filter((type) => type.value === "book");
            setFilteredProductTypes(filteredTypes);
        }
    }, [data.choosen_year, data.year]);
    useEffect(() => {
        if (data.product_id > 0 && data.submit_type && data.submit_type !== "store") {
            getProductInfo();
        }
        setData({ ...data, picture: "", to_edit_picture: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.product_id, data.submit_type, data.choosen_year]);

    useEffect(() => {
        let fields = [
            {
                id: "submit_type",
                placeholder: "هتعمل ايه دلوقتي",
                type: "select",
                options: submitTypes,
            },
        ];
        if (data.submit_type) {
            if (data.submit_type && data.submit_type !== "store") {
                if (isMultiYear) {
                    fields = [
                        ...fields,
                        {
                            id: "choosen_year",
                            placeholder: "اختر الصف الدراسي",
                            type: "select",
                            options: ProductYears,
                        },
                    ];
                } else {
                    fields = [
                        ...fields,
                        {
                            id: "choosen_year",
                            placeholder: "نوع المنتج",
                            type: "select",
                            options: [
                                {
                                    value: "3",
                                    text: "كتب",
                                },
                                {
                                    value: "4",
                                    text: "منتجات اخري",
                                },
                            ],
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "product_id",
                        placeholder: "اختر المنتج",
                        type: "select",
                        options: products,
                    },
                ];

                if (data.submit_type === "update") {
                    if (data.product_id > 0) {
                        fields = [
                            ...fields,
                            {
                                element: "html",
                                input: <ShowImage path={productPicture} isPath={true} />,
                            },
                        ];
                        if (data.choosen_year != 3 && data.choosen_year != 4) {
                            fields = [
                                ...fields,

                                {
                                    id: "semester",
                                    placeholder: "اختر الترم الدراسي",
                                    type: "select",
                                    options: semester,
                                },
                            ];
                        }
                        if (data.choosen_year == 4) {
                            fields = [
                                ...fields,
                                {
                                    id: "product_type",
                                    placeholder: "نوع المنتج",
                                    type: "select",
                                    options: filteredProductTypes,
                                },
                            ];
                        }

                        fields = [
                            ...fields,
                            {
                                id: "name",
                                placeholder: "اسم المنتج",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                            {
                                id: "description",
                                placeholder: "نبذة قصيرة",
                                type: "text",
                                icon: <InputIcon icon="material-symbols:description-outline" />,
                            },
                            {
                                id: "content",
                                placeholder: "شرح",
                                type: "textarea",
                                icon: <InputIcon icon="material-symbols:description-outline" />,
                            },
                            {
                                id: "sku_code",
                                placeholder: "كود المنتج",
                                type: "text",
                                icon: <InputIcon icon="cil:qr-code" />,
                            },
                            {
                                id: "weight",
                                placeholder: "وزن الكتاب / الباكدج (جرام)",
                                type: "number",
                                icon: <InputIcon icon="fluent:weight-gram-24-filled" />,
                            },
                            {
                                id: "price",
                                placeholder: "سعر المنتج",
                                type: "number",
                                icon: <InputIcon icon="solar:tag-price-bold-duotone" />,
                            },
                            {
                                id: "seller_price",
                                placeholder: "عائد البائع",
                                type: "number",
                                icon: <InputIcon icon="solar:tag-price-bold-duotone" />,
                            },
                        ];
                        if (isCategories) {
                            fields = [
                                ...fields,
                                {
                                    id: "category_id",
                                    placeholder: "الفئه",
                                    type: "select",
                                    options: [
                                        ...categoties,
                                        {
                                            value: 0,
                                            text: "بدون فئة",
                                        },
                                    ],
                                    icon: <InputIcon icon="ic:twotone-price-change" />,
                                },
                            ];
                        }

                        if (isMultiSellers) {
                            fields = [
                                ...fields,
                                {
                                    id: "teacher_id",
                                    placeholder: "البائع",
                                    options: teachers,
                                    type: "select",
                                },
                            ];
                            if (data.year != 4) {
                                fields = [
                                    ...fields,
                                    {
                                        id: "subject_id",
                                        placeholder: "المادة",
                                        options: subjects,
                                        type: "select",
                                    },
                                ];
                            }
                        }

                        fields = [
                            ...fields,
                            {
                                id: "to_edit_picture",
                                placeholder: "تعديل الصورة",
                                type: "switch",
                            },
                            {
                                id: "picture",
                                placeholder: "الصورة",
                                type: "file",
                                isDisabled: !data.to_edit_picture,
                            },
                            {
                                id: "is_visible",
                                placeholder: "ظاهر للمشتريين",
                                type: "switch",
                            },
                            {
                                id: "is_visible_for_outlet",
                                placeholder: "ظاهر للمنافذ",
                                type: "switch",
                            },
                            {
                                id: "is_outlet_stock_available",
                                placeholder: "يوجد استوك للمنافذ (متاح للطلب للمنفذ)",
                                type: "switch",
                            },
                        ];
                    }
                }
            }
            if (data.submit_type && data.submit_type === "store") {
                if (isMultiYear) {
                    fields = [
                        ...fields,
                        {
                            id: "year",
                            placeholder: "اختر الصف الدراسي",
                            type: "select",
                            options: ProductYears,
                        },
                    ];
                    if (data.year != 3 && data.year != 4) {
                        fields = [
                            ...fields,

                            {
                                id: "semester",
                                placeholder: "اختر الترم الدراسي",
                                type: "select",
                                options: semester,
                            },
                        ];
                    }
                } else {
                    fields = [
                        ...fields,
                        {
                            id: "year",
                            placeholder: "نوع المنتج",
                            type: "select",
                            options: [
                                {
                                    value: "3",
                                    text: "كتب",
                                },
                                {
                                    value: "4",
                                    text: "منتجات اخري",
                                },
                            ],
                        },
                    ];
                }
                if (data.year == 4) {
                    fields = [
                        ...fields,
                        {
                            id: "product_type",
                            placeholder: "نوع المنتج",
                            type: "select",
                            options: filteredProductTypes,
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "name",
                        placeholder: "اسم المنتج",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },
                    {
                        id: "description",
                        placeholder: "نبذة قصيرة",
                        type: "text",
                        icon: <InputIcon icon="material-symbols:description-outline" />,
                    },
                    {
                        id: "content",
                        placeholder: "شرح",
                        type: "textarea",
                        icon: <InputIcon icon="material-symbols:description-outline" />,
                    },
                    {
                        id: "sku_code",
                        placeholder: "كود المنتج",
                        type: "text",
                        icon: <InputIcon icon="cil:qr-code" />,
                    },
                    {
                        id: "weight",
                        placeholder: "وزن الكتاب / الباكدج (جرام)",
                        type: "number",
                        icon: <InputIcon icon="fluent:weight-gram-24-filled" />,
                    },
                    {
                        id: "price",
                        placeholder: "سعر المنتج",
                        type: "number",
                        icon: <InputIcon icon="solar:tag-price-bold-duotone" />,
                    },
                    {
                        id: "seller_price",
                        placeholder: "عائد البائع",
                        type: "number",
                        icon: <InputIcon icon="solar:tag-price-bold-duotone" />,
                    },
                ];

                fields = [
                    ...fields,
                    {
                        id: "picture",
                        placeholder: "الصورة",
                        type: "file",
                    },
                ];
                if (isCategories) {
                    fields = [
                        ...fields,
                        {
                            id: "category_id",
                            placeholder: "الفئه",
                            type: "select",
                            options: [
                                ...categoties,
                                {
                                    value: 0,
                                    text: "بدون فئة",
                                },
                            ],
                            icon: <InputIcon icon="ic:twotone-price-change" />,
                        },
                    ];
                }
                if (isMultiSellers) {
                    fields = [
                        ...fields,
                        {
                            id: "teacher_id",
                            placeholder: "البائع",
                            options: teachers,
                            type: "select",
                        },
                    ];
                    if (data.year != 4) {
                        fields = [
                            ...fields,
                            {
                                id: "subject_id",
                                placeholder: "المادة",
                                options: subjects,
                                type: "select",
                            },
                        ];
                    }
                }

                fields = [
                    ...fields,

                    {
                        id: "is_visible",
                        placeholder: "ظاهر للمشتريين",
                        type: "switch",
                    },
                    {
                        id: "is_visible_for_outlet",
                        placeholder: "ظاهر للمنافذ",
                        type: "switch",
                    },
                    {
                        id: "is_outlet_stock_available",
                        placeholder: "يوجد استوك للمنافذ (متاح للطلب للمنفذ)",
                        type: "switch",
                    },
                ];
            }
        }
        setInputFields(fields);
        // console.log(`product_id:${data.product_id}`);
    }, [
        categoties,
        data.product_id,
        data.submit_type,
        data.choosen_year,
        data.subject_id,
        data.to_edit_picture,
        productPicture,
        products,
        subjects,
        teachers,
        data.year,
        filteredProductTypes,
    ]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);
            const crudApiEndPoint = "/api/products";
            const crudItemName = "المنتج";

            handleCrud(
                config,
                data,
                crudApiEndPoint,
                crudItemName,
                setIsLoading,
                setErrors,
                setData,
                initialState,
                data.product_id
            );
        });
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="حفظ" // Adjust your button text as needed
            >
                {/* Render input fields dynamically */}
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default ProductEditing;
