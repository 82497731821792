import React, { createContext, useEffect, useState } from "react";
import cartOutletService from "../services/cartOutletServices";

export const CartOutletContext = createContext();

export const CartOutletProvider = ({ children }) => {
    const [cartOutlet, setCartOutlet] = useState(() => {
        const storedCart = cartOutletService.getCartOutlet();
        return storedCart ? JSON.parse(storedCart) : [];
    });
    const [totalPriceOutlet, setTotalPriceOutlet] = useState(0);
    const [totalQuantityOutlet, setTotalQuantityOutlet] = useState(0);

    useEffect(() => {
        cartOutletService.storeCartOutlet(cartOutlet);
        const totalPrice = cartOutlet.reduce((acc, product) => {
            return acc + product.price * product.quantity;
        }, 0);
        setTotalPriceOutlet(totalPrice);

        const totalQuantity = cartOutlet.reduce((total, product) => total + product.quantity, 0);
        setTotalQuantityOutlet(totalQuantity);
    }, [cartOutlet]);

    return (
        <CartOutletContext.Provider
            value={{ cartOutlet, setCartOutlet, totalPriceOutlet, totalQuantityOutlet, setTotalQuantityOutlet }}
        >
            {children}
        </CartOutletContext.Provider>
    );
};
